/**
 * Layout/content stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */


.top__bar {
  background-color: $color-bg;

  h1 {
    font-size: 32px;
  }
}

.content {
  .content__col, &.content__col {
    color: $color-text;

    .button {
      width: 250px;
    }

    table:not(.auto) {
      width: 100%;
      border: 0px !important;
      border-collapse: collapse !important;
      border-spacing: 0px !important;

      tbody {
        vertical-align: top;
      }

      th, td {
        text-align: left;
        border: 0px !important;
        border-collapse: collapse !important;
        border-spacing: 0px !important;
        padding: 4px;

        @include respond-max(480px) {
          display: inline-block !important;
        }

      }

      tr {
        border-bottom: solid 1px #f1f1f1;

        &:last-child {
          border: 0px;
        }
      }
    }

    img {
      & + h1 {
        margin-top: 20px !important;
      }

      & + h2 {
        margin-top: 20px !important;
      }

      & + h3 {
        margin-top: 20px !important;
      }

      & + strong {
        margin-top: 20px !important;
        display: inline-block;
      }
    }

    p, ul {
      & + h1 {
        margin-top: 20px !important;
      }

      & + h2 {
        margin-top: 20px !important;
      }

      & + h3 {
        margin-top: 20px !important;
      }

      & + strong {
        margin-top: 20px !important;
        display: inline-block;
      }
    }

    h1, h2, h3, h4, h5, h6, p {
      margin-bottom: 10px;
    }

    h1 {
      color: $color-primary;
      font-size: 36px;

      @include respond-max($sm) {
        font-size: 20px !important;
      }
    }

    h2 {
      font-family: $font-serif;
      color: $color-secondary;
      font-weight: $font-black;
      font-size: 24px;
    }

    h3, h4, h5, h6 {
      color: $color-text;
      font-weight: $font-bold;
      font-size: 20px;
    }

    ul {
      margin-left: $baseline;
      margin-bottom: $baseline;
      padding-left: 0;
      position: relative;
      list-style: none !important;

      li {
        position: relative;
        list-style: none !important;

        &:before {
          content: "\f111";
          font-family: "Font Awesome 5 Pro";
          font-weight: 700;
          font-size: 6px;
          color: $color-secondary;
          position: absolute;
          left: -20px;
          top: 10px;
        }
      }

      ul {
        margin-top: 0;
        margin-bottom: 0;
        list-style: none;

        li {
          position: relative;
          list-style: none !important;

          &:before {
            content: "\f111";
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            font-size: 6px;
            color: $color-secondary;
            position: absolute;
            left: -20px;
            top: 10px;
          }
        }
      }
    }

    ol {
      margin-left: $baseline;
      margin-bottom: $baseline;
      padding-left: 0;
      position: relative;
      list-style: none !important;

      > li {
        position: relative;
        list-style: none !important;
        counter-increment: counter;

        &:before {
          content: counter(counter);
          font-weight: $font-light;
          font-size: 16px;
          color: $color-secondary;
          position: absolute;
          left: -20px;
          top: 2px;
        }
      }

      ol {
        margin-top: 0;
        margin-bottom: 0;

        li {
          position: relative;
          list-style: none !important;
          counter-increment: counter2;

          &:before {
            content: counter(counter2);
            font-weight: $font-light;
            font-size: 16px;
            color: $color-secondary;
            position: absolute;
            left: -20px;
            top: 2px;
          }
        }
      }
    }

    ul {
      margin-left: 25px;
      list-style: disc !important;

      ul {
        list-style: circle !important;
        margin-left: 15px;
        margin-bottom: 10px;
      }

      & + * {
        margin-top: 20px;
      }
    }

    hr {
      margin-top: 20px;
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
