/**
 * Config/vars stylesheet
 *
 * Table of contents
 * 1) Breakpoints
 * 2) Colours
 * 3) Typography
 * 4) Layout
 * 5) Z-index
 * 6) Utility
 * 7) CDN
 */


/**
 * 1) Breakpoints
 */

$xs: 30em; // 480px --> $bp1
$sm: 48em; // 768px --> $bp2
$md: 62em; // 992px --> $bp3
$lg: 75em; // 1200px --> $bp4

$breakpoints: (
        $xs "xs",
        $sm "sm",
        $md "md",
        $lg "lg",
);


/**
 * 2) Colors
 */

$color-text: #444444;
$color-bg: #F1F1F1;
$color-link: #193741;

$color-white: #FFFFFF;
$color-grey: #9E9E9E;
$color-green: #7fc742;
$color-black: #000000;

$color-primary: #909090;
$color-secondary: #909090;

/**
 * 3) Typography
 */

$font-sans: "Poppins", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font-serif: "Poppins", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

$font-black: 700;
$font-bold: 700;
$font-regular: 400;
$font-light: 300;


/**
 * 4) Layout
 */

$baseline: 20px;


/**
 * 5) Z-index
 */

$z-index-1: 1;
$z-index-2: 5;
$z-index-3: 10;
$z-index-4: 15;
$z-index-5: 20;


/**
 * 6) Utility
 */

$spacing-sizes: (
        'xxs': .25,
        'xs': .5,
        's': 1,
        'one': 1,
        'm': 1.5,
        'l': 2,
        'two': 2,
        'three': 3,
        'xl': 4,
        'four': 4,
        'five': 5,
        'xxl': 6,
        'jumbo': 8,
        'none': 0,
);


$spacing-edges: (
        'top',
        'right',
        'bottom',
        'left',
        'vertical' ('top' 'bottom'),
        'horizontal' ('left' 'right'),
);


/**
 * 7) CDN
 */

$cdn: 'https://placehold.it/';
