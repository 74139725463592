/**
 * Webshop/filters stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */
/**
 * 1) Block
 */

@include respond-max(991px) {
  .filtertogglebutton {

    &.button {
      display: block !important;
      float: left;

      &.active {
        background-color: $color-white;
      }
    }
  }
}

.cms-active-filters {
  text-align: right;
  margin-bottom: 15px;

  .title {
    border: 0px !important;
    font-size: 12px;
    text-transform: uppercase;
    display: none;
  }

  a {
    background-color: $color-grey;
    color: $color-white;
    padding: 0 15px;
    font-size: 13px;
    line-height: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 3px;
    margin-left: 10px;
    text-decoration: none;

    @include respond-min($md) {
      &:hover {
        color: $color-white;
        background-color: $color-text;
      }
    }

    .close {
      &:after {
        content: "\f00d";
        font-family: "Font Awesome 5 Pro";
        font-size: 12px !important;
        margin-left: 10px;
      }
    }

    &.all {
      visibility: hidden;
      width: 30px;
      padding: 0 !important;
      overflow: hidden;
      display: inline-block;
      position: relative;
      top: 11px;

      @include respond-min($md) {
        &:hover {
          .close {
            &:after {
              color: $color-white;
              background-color: $color-text;
            }
          }
        }
      }

      .close {
        visibility: visible !important;
        overflow: hidden;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;

        &:after {
          content: "\f00d";
          font-family: "Font Awesome 5 Pro";
          background-color: $color-grey;
          color: $color-white;
          font-size: 13px;
          line-height: 30px;
          padding: 0 !important;
          text-align: center;
          height: 30px;
          width: 30px;
          border-radius: 3px;
          margin-left: 0px;
          text-decoration: none;
          display: inline-block !important;
        }
      }

    }

  }
}

.filter {
  position: relative;
  clear: both;
  transition: opacity 1s ease-out;
  overflow: hidden;
  padding-right: 0px;

  @include respond-min($md) {
    padding-right: 10px;
  }

  @include respond-max(991px) {
    opacity: 0;
    height: 0;

    label, .filter-toggle:after {
      color: $color-text !important;
    }
  }

  &.active {
    margin-top: 30px;
    opacity: 1;
    height: auto;
    overflow: visible;
  }

  .filters__search {
    padding-bottom: 5px;
    position: relative;

    .filters__search__input {
      box-sizing: border-box !important;
      width: 100%;
      font-size: 16px !important;
      font-style: italic !important;
      height: 40px;
      line-height: 40px;
      background: $color-bg !important;
      width: 100%;
      border-collapse: collapse;
      border-radius: 0 !important;
      border: 0px;
      margin-bottom: 5px;
      padding: 0 10px;
      box-sizing: border-box;
    }

    .filters__search__clear {
      display: none;
    }

    button {
      padding: 0;
      height: 40px;
      position: absolute;
      right: 0;
      top: 0px;
      bottom: 0;
      margin: 0;
      min-height: 1px;
      width: 35px;
      background: transparent;
      border: none;

      .fa-times {
        color: $color-text !important;
      }

      .fa-search {
        color: $color-black !important;
      }
    }
  }

  .title {
    color: $color-black;
    text-transform: none;
    font-weight: $font-bold;
    margin-bottom: 10px;
    font-size: 18px;
    display: block;
    line-height: 20px;
    min-height: 20px;
  }

  .filters {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-bg;
    display: inline-block;
    position: relative;
    width: 100%;
    clear: both;
  }

  .filters__list {
    transition: all .3s ease;
    list-style: none !important;
    margin-left: 0 !important;
  }

  .filter-toggle {
    color: transparent;
    width: 100%;
    display: block;
    position: relative;
    width: 100%;
    clear: both;
    height: 0;

    &.is-open {
      &:after {
        content: "\f106";
        font-family: "Font Awesome 5 Pro";
      }
    }

    &:after {
      content: "\f107";
      font-family: "Font Awesome 5 Pro";
      color: $color-secondary;
      font-weight: $font-light;
      position: absolute;
      right: 0;
      font-size: 25px;
      bottom: -20px;
    }
  }
}

.rangeFilter {
  margin-bottom: 20px;
  padding-bottom: 20px;
  line-height: 45px;
  min-height: 45px;
  font-size: 16px;
}

.rangeFilter .slider-range {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
}

.rangeFilter .ui-button, .rangeFilter .ui-state-default, .rangeFilter .ui-widget-content .ui-state-default, .rangeFilter .ui-widget-header .ui-state-default, html .rangeFilter .ui-button.ui-state-disabled:active, html .rangeFilter .ui-button.ui-state-disabled:hover {
  border: 1px solid darken($color-secondary, 15%);
  background: darken($color-secondary, 15%);
  border-radius: 100%;
}

.rangeFilter .ui-widget.ui-widget-content {
  border: 0px;
  margin: 10px 0 0 10px;
}

.rangeFilter .ui-widget-header {
  background-color: $color-secondary;
}

.rangeFilter .ui-slider-horizontal .ui-slider-range {
  height: 8px;
}

.rangeFilter .ui-widget-content {
  background: darken($color-bg, 10%);
  height: 8px !important;
}

.ui-slider .ui-slider-handle {
  top: -.3em;
  margin-left: -.6em;
  width: 1em !important;
  height: 1em !important;
}

/**
 * 2) Element
 */

.filters__item {
  display: none;
  position: relative;
  font-size: 16px;
  font-weight: $font-regular;
  margin-bottom: 3px;

  .filters__label {
    font-weight: $font-regular;
    color: $color-text;
    margin-left: 20px;
    line-height: 20px;

    .count {
      color: $color-grey;
    }

    a {
      position: relative;
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
      color: $color-text;
      font-weight: $font-regular;
    }

    &:after {
      content: "";
      position: absolute;
      border: 1px solid darken($color-bg, 10%);
      background: #fff;
      height: 16px;
      left: 0;
      top: 1px;
      width: 16px;
      display: block;
      visibility: visible;
    }
  }

  &.selected {
    .filters__label {
      color: $color-text !important;
      font-weight: $font-bold;

      a {
        color: $color-text !important;
      }

      &:before {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        left: 0px;
        top: -1px;
        font-size: 20px;
        z-index: 1;
        color: $color-secondary;
        height: 20px;
        display: block;
        width: 20px;
        visibility: visible;
      }
    }

  }

  input[type='checkbox'] {
    visibility: hidden;
    width: 0;
    margin-right: 0;
  }

}

.filters__label {
  display: block;
  margin-left: 20px;
  color: $color-text;
}

.active-filters__item {
  background-color: darken($color-bg, 2%);
  border: 1px solid darken($color-bg, 5%);
  cursor: pointer;
}

/**
 * 3) Modifier
 */

.cms-shop-articles-inner {
  display: block;
  clear: both;
}

.cms-articles-header {
  overflow: hidden;
  clear: both;
  margin-bottom: 20px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid $color-bg;
}

.cms-articles-footer {
  overflow: hidden;
  clear: both;
  margin-top: 20px;
  padding: 10px 0 0 0;
  border-top: 1px solid $color-bg;
}

.cms-articles-header, .cms-articles-footer {

  .cms-pagination {
    float: right;
    padding-top: 0px;

    span.item.prev, span.item.next, span.seperator {
      display: none !important;
    }

    span {
      &.selected {
        color: $color-secondary;
        font-weight: $font-regular;
        font-size: 15px;
      }
    }

    a {
      &.item {
        text-decoration: none;

        &.selected, &:hover {
          color: $color-secondary;
        }
      }
    }

    .item {
      font-size: 14px;
      color: $color-text;
      font-weight: $font-regular;
      display: block;
      float: left;
      min-width: 12px;
      line-height: 38px;
      height: 38px;
      min-width: 38px;
      padding: 0 8px;
      margin-left: 5px;
      margin-bottom: 5px;
      text-align: center;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      border: 1px solid $color-bg;
      position: relative;

      &.selected, &:hover {
        color: $color-secondary;
        border: 1px solid $color-secondary;
        font-weight: $font-regular;
      }
    }
  }

}

.cms-articles-sorting {
  position: relative;
  display: block;
  //display: none !important;

  @include respond-min($xs) {
    min-width: 450px;
    display: inline-block;
  }

  input[type=submit] {
    float: left;
    display: none;
  }

  select {
    font-size: 14px !important;
    min-width: 140px;
    width: 200px;
    display: inline-block;
    line-height: 1;
    margin-right: 1%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;

    @include respond-max(480px) {
      min-width: 100%;
      width: 100%;
    }
  }
}