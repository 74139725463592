/**
 * Modules/logo stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */


.logo__col {
  @include respond-max(991px) {
    position: absolute;
  }
}

.logo {
  display: block;
  margin-top: 5px;
  transition: all .3s ease;

  @include respond-max(991px) {
    text-align: center;
    margin: 0 auto;
    width: 160px;
    margin-top: 5px;
  }

  svg, img {
    width: 160px;
    height: 66px;
    transition: all .3s ease;
  }

  @include respond-min($md) {
    display: block;
    margin-top: 5px;

    svg, img {
      width: 250px;
      height: 82px;
      transition: all .3s ease;
    }
  }

  @include respond-between($md, 1199px) {
    display: block;
    margin-top: 20px;

    svg, img {
      width: 145px;
      height: 35px;
      transition: all .3s ease;
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
