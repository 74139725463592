/**
 * Webshop/buttons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.product {

  .loyalty__points {
    margin-bottom: 20px;
    display: block;
    text-decoration: none;
  }

  .anchors {
    ul {
      margin-left: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-align-content: space-around;
      -ms-flex-line-pack: distribute;
      align-content: space-around;

      @include respond-max(480px) {
        display: block !important;
      }

      .anchor__item {
        list-style: none;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-flex-shrink: 1;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        line-height: 50px;
        min-height: 50px;

        background-color: $color-bg;
        text-align: center;

        &:before {
          display: none;
        }

        @include respond-max(480px) {
          display: block !important;
        }

        &.selected {
          background-color: $color-primary;

          .anchor__link {
            color: $color-white;
          }

        }

        .anchor__link {
          font-size: 18px;
          display: block;
          line-height: 45px;
          min-height: 45px;
          text-decoration: none;
          color: $color-black;
          font-weight: $font-bold;
        }
      }
    }
  }

  .anchor__tab {
    position: relative;

    .reviews {
      .total {
        font-size: 14px;
      }

      .review__items {
        height: 400px;
        overflow-y: scroll;

        .item {
          border-bottom: 1px solid $color-bg;
          padding: 10px 0;

          &:first-child {
            border-top: 1px solid $color-bg;
          }

          .stars {
            .fa, .fab, .fad, .fal, .far, .fas {
              color: $color-black;
              font-size: 11px;
              margin-bottom: 5px;
            }
          }

          .date {
            font-size: 13px;
            margin-bottom: 5px;
            color: $color-grey;
          }

          h3 {
            font-size: 15px;
            margin-bottom: 3px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }

    table:not(.auto) {
      width: 100%;
      border: 0px !important;
      border-collapse: collapse !important;
      border-spacing: 0px !important;

      tbody {
        vertical-align: top;
      }

      th, td {
        text-align: left;
        border: 0px !important;
        border-collapse: collapse !important;
        border-spacing: 0px !important;
        padding: 4px 10px;

        @include respond-max(480px) {
          display: inline-block !important;
        }
      }

      tr {
        color: $color-text;

        &:nth-child(odd){
          background-color: $color-bg;
        }

        &:last-child {
          border: 0px;
        }

        td {
          &:nth-child(2n+2) {
            font-weight: $font-bold;
          }
        }
      }
    }

    h2 {
      color: $color-black;
      font-family: $font-serif;
      font-size: 32px !important;
    }

    h3 {
      color: $color-black;
      font-family: $font-sans !important;
      font-size: 20px !important;
      text-transform: none !important;
    }

    p {
      color: $color-text;
    }

    strong {
      font-weight: $font-bold;
    }
  }

  .preview {

    .inner {
      @include respond-max(767px) {
        max-width: 320px;
        margin: 0 auto;
      }
    }

    .label {
      position: absolute;
      top: 20px;
      left: 10px;
      font-size: 15px;
      padding: 6px 10px;
      background-color: $color-primary;
      border-radius: 0 4px 4px 0;
      color: $color-white;
      z-index: 15;

      @include respond-max(480px) {
        left: auto;
        border-radius: 4px 0 0 4px;
        right: 10px;
        font-size: 12px;
        padding: 4px 8px;
      }

      &.sale {
        background-color: $color-secondary;
        left: auto;
        right: 10px;
        border-radius: 4px 0 0 4px;
      }
    }

    .u-margin-top.cf {
      display: block;
      color: $color-secondary;
      line-height: 40px;
      border: 1px solid $color-secondary;
      width: 250px;
      text-align: center;
      text-decoration: none;
      margin-top: 60px !important;

      @include respond-min($md) {
        &:hover {
          text-decoration: none;
          color: $color-primary;
          border-color: $color-primary;
        }
      }
    }

    @include respond-max(768px) {
      margin-bottom: 30px;
    }

    .gallery {
      margin-top: 0px;
    }

    .slick-dots {
      margin-left: 0;
      text-align: center;

      @include respond-max(480px) {
        margin-left: 20px;
      }

      li {
        position: relative;
        list-style: circle;
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        &:before {
          display: none !important;
        }

        &.slick-active {
          button {
            &:before {
              content: "\f111";
              font-family: "Font Awesome 5 Pro";
              color: lighten($color-black, 65%);
              font-weight: $font-bold;
            }
          }
        }

        button {
          font-size: 0;
          line-height: 0;
          display: block;
          padding: 0;
          min-height: inherit !important;
          line-height: inherit !important;
          cursor: pointer;
          color: $color-black;
          border: 0;
          outline: none;
          background: transparent;

          &:before {
            content: "\f111";
            font-family: "Font Awesome 5 Pro";
            color: lighten($color-black, 75%);
            font-size: 7px;
            font-weight: $font-light;
          }
        }
      }
    }

    .js-gallery {
      position: relative;
      border: 1px solid $color-bg;

      .inner {
        img {
          cursor: zoom-in;
        }
      }
    }

    .box {
      position: relative;
      background-color: #fafafa;
      overflow: hidden;

      img.zoom {
        min-width: 100%;
        height: auto !important;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }

    .gal {
      .thumb {
        float: left;
        width: 25%;
        position: relative;

        a {
          background-repeat: no-repeat;
          background-size: contain;
          padding-bottom: 100%;
          display: block;
          background-position: center;
          width: 100%;
          text-decoration: none;
        }
      }
    }

    .gallery--nav_wrapper {
      position: relative;
      margin-top: 20px;
      overflow: hidden;
      padding: 0 40px;

      &.no-arrows {
        padding: 0 !important;
        margin-left: -5px;
      }

      .prevArrow {
        position: absolute;
        z-index: 3;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 32px;
        color: $color-grey;
        background-color: transparent;
        text-decoration: none;

        .fa {
          font-weight: $font-light;
        }
      }

      .nextArrow {
        position: absolute;
        z-index: 3;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 32px;
        color: $color-grey;
        background-color: transparent;
        text-decoration: none;

        .fa {
          font-weight: $font-light;
        }
      }

      .gallery--nav {

        .slick-list {
          padding: 20px 0;
        }

        .gallery__item--nav {
          margin: 0 20px;
          height: auto !important;

          .image {
            background-size: cover;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: center !important;
            border: 1px solid $color-bg;
            padding-bottom: 125%;
          }
        }
      }

    }
  }

  .prod__info {

    .usps {
      font-weight: $font-light;
      font-size: 14px;
      line-height: 45px;

      @include respond-max(991px) {
        line-height: 35px;
        font-size: 12px;
      }

      ul {

        li {
          font-family: $font-serif;
          font-weight: $font-light;
          display: block;
          font-size: 14px;
          margin-right: 10px;
          line-height: 35px;

          @include respond-max(991px) {
            font-size: 12px;
          }

          .fal {
            font-size: 20px;
            color: $color-green;
            margin-right: 9px;
            position: relative;
            top: 2px;
            width: 25px;
          }

          strong {
            font-weight: $font-light;
            color: $color-green;
          }
        }
      }


    }

    .favorite {

      a {
        text-decoration: none;
      }

      .fa {
        font-weight: 600;
        color: darken($color-bg, 10%);
        z-index: 4;
        font-size: 20px;
        background-color: $color-bg;
        height: 35px;
        line-height: 35px;
        width: 35px;
        border-radius: 100%;
        display: block;
        text-align: center;

        position: absolute;
        top: 5px;
        right: 0px;
        z-index: 4;

        &.active {
          color: $color-secondary;
          font-weight: 600;
          background-color: lighten($color-secondary, 30%);
        }

        @include respond-min($md) {
          &:hover {
            color: lighten($color-secondary, 30%);
            font-weight: 600;
            background-color: $color-secondary;
          }
        }
      }

    }

    .variation {
      line-height: 45px;

      select {
        background-color: $color-white;
      }
    }

    .inner {
      //position: relative;
      //z-index: 1;

      .table {
        line-height: 12px;
        font-size: 14px;

        p {
          margin-bottom: 0;
        }
      }

      h1 {
        color: $color-black;
        font-weight: $font-regular;
        font-size: 38px;
        font-family: $font-serif;
        padding-top: 15px;
        margin-bottom: 10px;

        @include respond-between($sm, 991px) {
          font-size: 32px;
        }

        @include respond-max(767px) {
          font-size: 20px !important;
        }
      }

      h2 {
        font-size: 22px;

        @include respond-max(767px) {
          font-size: 18px !important;
        }
      }

      hr {
        margin-bottom: 10px;
        border-color: $color-bg;
      }

      .nostock {
        margin-bottom: 5px;
        color: $color-grey;
        text-transform: none;
        font-size: 18px;
        font-weight: $font-regular;
        font-family: $font-sans;

        .fa, .fas {
          font-weight: $font-bold;
          margin-right: 10px;
          width: 25px;
        }
      }

      .stock {
        margin-bottom: 5px;
        clear: both;
        color: $color-green;
        text-transform: none;
        font-size: 18px;
        font-weight: $font-regular;
        font-family: $font-sans;

        .fa, .fas {
          font-weight: $font-bold;
          margin-right: 10px;
          width: 25px;
        }
      }

      .buy {
        margin-bottom: 10px;

        .oldprice {
          float: left;
          color: $color-grey;
          font-weight: $font-light;
          font-size: 23px;
          text-decoration: line-through;
          margin-right: 10px;
          position: relative;
          top: 9px;
        }

        .price {
          font-size: 32px;
          color: $color-primary;
          font-weight: $font-black;
        }

        .vat {
          font-size: 16px;
          color: $color-text;
          font-weight: $font-regular;
          margin-bottom: 5px;
          position: relative;
          top: -1px;
          margin-left: 10px;
        }
      }

      .order {
        position: relative;

        &.addedtocart {
          .message_addedtocart {
            visibility: visible;
            transition: all .3s ease;
          }

          &:before {
            transition: all .3s ease;
            visibility: visible;
          }
        }

        &:before {
          content: "";
          background: rgba($color-white, 1);
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          transition: all .3s ease;
          height: 100%;
          width: 100%;
          z-index: 10;
          visibility: hidden;
        }

        .message_addedtocart {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          color: $color-green;
          visibility: hidden;
          z-index: 11;
          width: 100%;
          padding: 20px;
          text-align: center;
          transition: all .3s ease;

          span {
            font-size: 16px;
            font-weight: $font-bold;
            display: inline-block;
            margin-left: 20px;
          }

          .fa, .fab, .fal, .far, .fas {
            font-size: 22px;
            display: inline-block;
          }
        }

        .addtocart {
          padding: 0 15px !important;
          height: 70px !important;
          line-height: 70px !important;
          display: inline-block;
          background-color: $color-green;
          font-weight: $font-light;
          font-family: $font-serif;
          font-size: 22px;
          float: left;
          margin-top: 0 !important;
          width: 100%;

          @include respond-min($md) {
            width: 50%;
          }

          @include respond-between($xs, 767px) {
            width: 50%;
          }

          .ui-icon {
            display: none;
          }

          &:before {
            content: "\f49e";
            transition: all .3s ease;
            margin-right: 10px;
            font-weight: $font-light;
            font-size: 22px;
            font-family: "Font Awesome 5 Pro";
          }

          @include respond-min($md) {
            &:hover {
              background-color: darken($color-green, 10%) !important;

              &:after {
                transition: all .3s ease;
                margin-left: 15px;
              }
            }
          }
        }

        .amountbox {
          background-color: $color-bg;
          float: left;
          display: inline-block;
          border-radius: 4px;
          padding: 10px;
          height: 70px;
          line-height: 50px;
          margin-right: 20px;
          width: 100%;

          @include respond-between($sm, 991px) {
            margin-bottom: 5px;
          }
          @include respond-max(480px) {
            margin-bottom: 5px;
          }

          @include respond-min($md) {
            width: calc(50% - 20px);
          }

          @include respond-between($xs, 767px) {
            width: calc(50% - 20px);
          }

          span {
            font-weight: $font-bold;
            margin-left: 20px;
          }

          .qtyminus, .qtyplus {
            max-width: 40px;
            top: 1px;
            position: relative;
            min-width: 20px;
            line-height: 50px;
            min-height: 50px;
            padding: 0 5px;
            float: left;
            color: darken($color-bg, 25%);
            background-color: darken($color-bg, 10%);
          }

          .qtyplus {
            border-radius: 0 4px 4px 0;
          }

          .qtyminus {
            border-radius: 4px 0 0 4px;
          }

          input {
            max-width: 45px;
            margin-top: 1px;
            padding: 0 10px;
            height: 50px;
            border-radius: 0;
            float: left;
            font-size: 18px !important;
            background-color: $color-white;
            text-align: center;
            color: darken($color-bg, 25%);
          }
        }
      }

    }
  }

}


/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
