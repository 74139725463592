/**
 * Base/elements stylesheet
 *
 * Table of contents
 * 1) Basics
 * 2) Link
 * 3) Blockquotes
 * 4) Embedded
 */


/**
 * 1) Basics
 */

hr {
  border: 0;
  border-top: 1px solid $color-bg;
}

ins {
  text-decoration: none;
  color: #000;
  background-color: #ff9;
}

mark {
  font-style: italic;
  font-weight: bold;
}

pre {
  overflow-x: auto;
}

address {
  white-space: pre-line;
}


/**
 * 2) Link
 */

a:not([class]) {
  text-decoration: none;
  color: $color-link;

  @include respond-min($md) {
    &:hover {
      color: darken($color-link, 10%);
    }
  }
}

a[href^="tel"]:not([class]) {
  color: $color-link;

  @include respond-min($md) {
    &:hover {
      color: darken($color-link, 10%);
    }
  }
}


/**
 * 3) Blockquotes
 */

blockquote {
  clear: both;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;

  @include respond-min($sm) {
    padding-left: $baseline*2;
    padding-right: $baseline*2;
  }

  p {
    @extend %u-text-large !optional;
  }
}


/**
 * 4) Embedded
 */

img {
  display: inline-block;
  margin: 0 auto;
  font-style: italic;
  color: $color-text;
  vertical-align: middle;

  &[width] {
    width: auto !important;
  }

  &[height] {
    height: auto !important;
  }

  &[src$='.svg'] {
    width: 100%;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &[src$='.svg'] {
      width: 100%;
    }
  }
}

svg {
  width: 100%;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    & {
      width: 100%;
    }
  }
}

img,
audio,
canvas,
embed,
object,
video {
  max-width: 100%;
}

img {
  height: auto !important;
}

iframe {
  display: block;
}
