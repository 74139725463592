/**
 * Modules/icons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

@include respond-between(1200px, 1269px) {
  .searchbar {
    &.active {
      .box {
        input {
          width: 680px !important;
        }
      }
    }
  }
}

@include respond-between($md, 1200px) {
  .searchbar {
    &.active {
      .box {
        input {
          width: 550px !important;
        }
      }
    }
  }
}

@include respond-min($md) {

  .searchbar {
    z-index: 100;
    width: auto;
    position: absolute;
    transition: all .3s ease;
    z-index: 200;
    right: 15px;
    width: 74px;
    top: 0px;

    @include respond-between($md, 1199px) {
      right: 15px;
      width: 64px;
    }

    &.active {
      width: 100%;
      transition: all .2s ease;
    }

    .search {
      transition: all .3s ease;
      position: relative;
      height: 100%;
      z-index: 0;
      overflow: hidden !important;
      height: 110px;
      line-height: 110px;
      text-align: right;
      margin-top: 0px;

      &.active {
        width: auto;
        overflow: visible;
        z-index: 10;

        .box {
          transition: all .2s ease;
          opacity: 1;
          display: block;

          input {
            width: calc(100% - 300px);
            left: auto;
            right: 72px;

            @include respond-min(1351px) {
              width: calc(100% - 102px) !important;
            }

            @include respond-between($lg, 1350px) {
              width: calc(100% - 103px) !important;
              right: 72px;
            }
            @include respond-between($md, 1199px) {
              width: calc(100% - 91px)!important;
              right: 61px;
            }
          }
        }
      }

      .box {
        transition: all .2s ease;
        opacity: 0;
        display: block;

        input {
          font-size: 15px;
          padding: 0 20px;
          box-sizing: border-box;
          border: 0;
          height: 110px;
          line-height: 110px;
          background: $color-bg;
          border-radius: 10px !important;
          -webkit-appearance: none;
          border-radius: 0px !important;
          position: absolute;
          right: 74px;
          z-index: 200;
          font-style: normal !important;
          color: $color-text !important;
          text-align: left !important;
          transition: all .3s ease;

          &::-webkit-input-placeholder {
            color: $color-grey !important;
            font-style: italic;
          }

          @include respond-between(991px, 1350px) {
            right: 0px;
          }

        }
      }

      button {
        -webkit-appearance: none;
        background: 0 0;
        border: 0;
        border-collapse: collapse;
        border: 0px;
        box-sizing: border-box;
        top: 0;
        position: relative;
        height: 110px;
        outline: 0;
        right: 0;
        float: right;
        text-align: right;
        z-index: 1;
        padding: 0 24px;
        background-color: none;
        border-left: 1px solid $color-bg;
        border-right: 1px solid $color-bg;
        transition: all .3s ease;

        @include respond-between($md, 1199px) {
          padding: 0 20px !important;
        }

        &:hover {
          &:after {
            color: $color-secondary;
          }
        }

        &:after {
          content: "\f002";
          font-family: "Font Awesome 5 Pro";
          color: $color-black;
          font-size: 22px;
          width: 35px;
          text-align: center;
          padding: 0;

          @include respond-between($md, 1199px) {
            font-size: 20px !important;
          }
        }

      }
    }
  }
}

@include respond-max(991px) {
  .search {
    position: relative;
    padding: 20px 20px 0 20px;

    form {
      position: relative;
    }

    button {
      -webkit-appearance: none;
      background: 0 0;
      border: 0;
      border-collapse: collapse;
      padding: 0 !important;
      top: 0;
      position: absolute;
      width: 40px;
      outline: 0;
      line-height: 40px;
      height: 40px;
      right: 0;
      z-index: 1;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+32 */
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 32%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 32%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 32%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */

      &:after {
        content: "\f002";
        font-family: "Font Awesome 5 Pro";
        color: $color-secondary;
        font-size: 22px;
        height: 22px;
        width: 22px;
        text-align: center;
        padding: 10px;
      }

    }

    input {
      font-size: 14px;
      padding: 0 20px;
      box-sizing: border-box;
      border: 0;
      background: $color-white;
      border-radius: 10px !important;
      height: 40px;
      line-height: 40px;
      -webkit-appearance: none;
      border-radius: 0px !important;
      position: relative;
      width: 100%;
      font-style: normal !important;
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
