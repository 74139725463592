/**
* Webshop/Basket stylesheet
*
* Table of contents
* 1) Block
* 2) Element
* 3) Modifier
*/

/**
* 1) Block
*/

.ui-datepicker {
  border: 1px solid #e6e6e6 !important;
  width: 480px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  @include respond-max(768px) {
    width: 320px !important;
  }

  .ui-state-hover {
    background-color: $color-black;
    color: $color-white;
  }

  .ui-datepicker-calendar {

    tr {
      &.ui-state-active {
        padding: 2px !important;
        text-decoration: none !important;
        background-color: lighten($color-secondary, 20%) !important;
        color: $color-white !important;
        border: 0 !important;
      }
    }

    td {
      padding: 2px;

      span {
        padding: 2px;
      }

      .ui-button.ui-state-active:hover, .ui-button:active, .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-state-hover {
        padding: 2px !important;
        text-decoration: none !important;
        background-color: $color-secondary !important;
        color: $color-white !important;
        border: 0 !important;
      }

      .ui-state-default {
        padding: 2px !important;
        text-decoration: none !important;
        background: $color-bg;
        color: $color-primary;
        text-align: center !important;
      }
    }
  }

  .ui-datepicker-header, .ui-datepicker-title {
    min-height: 40px !important;
    line-height: 40px !important;
    padding: 0 !important;
    border: 0px !important;
  }

  .ui-datepicker-group-first {
    width: calc(50% - 5px) !important;
    float: left !important;

    @include respond-max(786px) {
      width: 100% !important;
      float: none !important;
    }
  }

  .ui-datepicker-group-last {
    width: calc(50% - 5px) !important;
    float: right !important;

    @include respond-max(786px) {
      width: 100% !important;
      float: none !important;
    }
  }

  .ui-datepicker-prev, .ui-datepicker-next {
    top: 0 !important;
    border-radius: 0;
  }

  .ui-datepicker-prev-hover, .ui-datepicker-next-hover {
    top: 0 !important;
    background-color: darken($color-bg, 50%);
  }

  .ui-datepicker-prev {
    background-color: darken($color-bg, 20%);
    min-height: 40px;
    line-height: 40px;
    text-align: center;
    width: 30px;
    display: block;
    left: 0 !important;
    float: left;
    margin-right: 10px;

    .ui-icon {
      display: none;
    }

    &:before {
      content: '\f104';
      font-family: "Font Awesome 5 Pro";
      color: $color-black;
    }
  }

  .ui-datepicker-next {
    background-color: darken($color-bg, 20%);
    min-height: 40px;
    line-height: 40px;
    text-align: center;
    right: 0px !important;
    width: 30px;
    display: block;
    float: right;
    margin-left: 10px;

    .ui-icon {
      display: none;
    }

    &:before {
      content: '\f105';
      font-family: "Font Awesome 5 Pro";
      color: $color-black;
    }
  }

  .ui-datepicker-prev-hover {
    background-color: darken($color-bg, 30%) !important;
    min-height: 40px;
    line-height: 40px;
    text-align: center;
    width: 30px;
    display: block;
    float: left;
    margin-right: 10px;
    border: 0px !important;

    &:before {
      content: '\f104';
      font-family: "Font Awesome 5 Pro";
      color: $color-black;
    }
  }

  .ui-datepicker-next-hover {
    background-color: darken($color-bg, 30%) !important;
    min-height: 40px;
    line-height: 40px;
    text-align: center;
    width: 30px;
    display: block;
    float: right;
    margin-left: 10px;
    border: 0px !important;

    &:before {
      content: '\f105';
      font-family: "Font Awesome 5 Pro";
      color: $color-black;
    }
  }
}


@include respond-max($md) {
  #bSteps ul li span {
    display: block;
    color: $color-black !important;
  }
  #bSteps ul li strong span {
    display: block;
    color: $color-white !important;
  }
  #bSteps ul li, #bSteps ul li strong {
    color: transparent !important;
    display: block;
  }
}

.bErrors {
  background: rgba(244, 67, 54, 0.29);
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  color: #e61a0b;
}

.bMessage {
  text-align: center;
  background: rgb(254, 236, 219);
  padding: 12px;
  border-radius: 0px;
  margin-bottom: 20px;
  color: #fa861e;
}

input.error, textarea.error {
  input:-moz-placeholder, *::-webkit-input-placeholder {
    color: #e61a0b !important;
  }

  color: #e61a0b !important;
  background: rgba(244, 67, 54, 0.29);
}

label.error {
  color: #e61a0b !important;
}

// Webshop basket

#bBasket .bCol textarea, #bBasket .bRow .bCol.value .bCol input.text {
  font-size: 14px !important;
  height: 40px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  line-height: 40px;
  background: $color-bg;
  width: 100%;
  border-collapse: collapse;
  border: 0px;
  margin-bottom: 5px;
  padding: 0 10px;
  box-sizing: border-box;
}

#bBasket .bCol input.text, #bBasket .bCol select {
  background-color: $color-bg;
  font-size: 14px !important;
  height: 40px !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  line-height: 40px !important;
  width: 100%;
  border-collapse: collapse !important;
  border: 0px !important;
  margin-bottom: 5px !important;
  padding: 0 10px !important;
  box-sizing: border-box !important;
}

#bBasket {
  clear: both;
}

#bBasket a:not(.btn--primary) {
  cursor: pointer;
  //color: $color-primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

#bBasket .clearfix:after,
#bBasket .inner:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}

#bBasket .lightColor {
  color: inherit;
  padding: 20px 0;
  border-radius: 8px;
}

#bBasket .grayed {
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
}

#bBasket input.text,
#bBasket select.country {
  width: 48%;
  margin: 0;
}

#bBasket .icheckbox_flat,
#bBasket .iradio_flat {
  margin-right: 5px;
  margin-bottom: 0;
}

#bBasket .bRow .bCol.value .bCol input.text {
  width: 100%;
}

#bBasket .btnIcon {
  margin: 0;
  padding: 0;
  width: 25px;
  height: 25px;
  line-height: 25px !important;
  white-space: nowrap;
  display: inline-block;
  border: none;
  cursor: pointer;
  outline: 0;
}

#bBasket .btnIcon.btnUpdateLine {
  margin-left: 5px;
  position: relative;
  top: 0px;
  background: url("/assets/dist/img/btn-refresh.png") no-repeat center center !important;
  background-size: contain !important;
  border-radius: 100%;
}

#bBasket .btnIcon.btnRemoveLine {
  margin-left: 5px;
  position: relative;
  top: 0px;
  background: url("/assets/dist/img/btn-remove.png") no-repeat 50% !important;
  background-size: contain !important;
  border-radius: 100%;
}

#bBasket .btnIcon.btnAddLine {
  margin-left: 5px;
  background: url("/assets/dist/img/btn-add.png") no-repeat center center !important;
}

#bBasket .btnIcon.icomoon, #bBasket .btnIcon.fa {
  -webkit-tap-highlight-color: transparent;
  color: #4d4d4d;
  -moz-border-radius: 0.25em;
  -webkit-border-radius: 0.25em;
  border-radius: 0.25em;
  background-color: $color-bg;
  background: -moz-linear-gradient(top, $color-bg 0%, $color-bg 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $color-bg), color-stop(100%, $color-bg));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $color-bg 0%, $color-bg 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $color-bg 0%, $color-bg 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $color-bg 0%, $color-bg 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, $color-bg 0%, $color-bg 100%);
  /* W3C */
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

#bBasket .btnIcon.icomoon:hover, #bBasket .btnIcon.fa:hover {
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
  color: $color-text;
}

#bBasket .button.back {
  float: left;
  min-width: 120px;
  text-decoration: none !important;
}

#bBasket .button.back:before {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f104";
  margin-right: 0.5em;
  font-size: 1.1em;
}

#bBasket .button.checkout:after {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f291";
  margin-left: 0.5em;
  font-size: 1.1em;
}

#bBasket .button.coupon:after {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e813";
  margin-left: 0.5em;
  font-size: 1.1em;
}

#bBasket .submit {
  min-width: 120px;
  float: right;
}

#bBasket .actions .button,
#bBasket .actions button {
  font-size: 1.1em;
  min-width: 150px;
  min-height: 50px;
  line-height: 50px;

}

#bBasket .required .title span:before {
  content: '* ';
}

#bBasket input.radio {
  float: left;
  clear: left;
  margin: 0 0.5em 0.5em 0;
}

#bBasket label {
  color: inherit;
  font-size: 1em;
  font-weight: normal;
  margin: 0;
}

#bBasket label.destination, #bBasket label.shipping, #bBasket label.payment {
  float: left;
  vertical-align: middle;
}

#bBasket label.radio, #bBasket label.checkbox {
  float: left;
  margin: 0 10px 0 0;
  padding: 5px;
}

#bBasket label.radio input {
  margin: 0 5px 0 0;
  float: none;
  position: static;
}

#bBasket span.title {
  color: $color-text;
  word-wrap: break-word;
}

#bBasket .giftcars input.text {
  width: 90%;
  height: 30px;
  vertical-align: middle;
}

#bBasket .giftcars .alignRight {
  text-align: left;
}

#bBasket .bSpacer {
  margin-bottom: 1em;
}

#bBasket .bRow {
  background: none;
}

#bBasket .bRow.line {
  border-bottom: 1px dotted #c1c2c4;
  padding-bottom: 1px;
}

#bBasket .bRow.last,
#bBasket .bRow.bTotal {
  border-bottom: none;
}

#bBasket .bRow > .bCol > .inner {
  padding: 8px;
}

#bBasket .bRow.bHead > .bCol > .inner {
  padding: 8px;
}

#bBasket .bRow > .bCol.bDelivery > .inner,
#bBasket .bRow > .bCol.bInvoice > .inner {
  padding: 8px;
}

#bBasket .bRow > .bCol.title > .inner {
  padding: 13px 0;
}

#bBasket .bRow > .bCol > .inner .inner {
  padding: 0;
}

#bBasket .bRow > .bCol.option label {
  display: inline-block;
  padding: 5px;
  vertical-align: middle;
}

#bBasket .bRow > .bCol.info.form {
  display: block;
}

#bBasket .bRow > .bCol.adjust .text.amount {
  width: 25%;
  height: 25px;
  max-width: 50px;
  line-height: 25px;
  margin: 0 8px 0 0;
  text-align: right;
  float: left;
  padding: 0 5px;
}

#bBasket .bRow > .bCol.adjust textarea {
  resize: none;
}

#bBasket .bRow > .bCol.article a:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}

#bBasket .bRow > .bCol.article .title {
  display: inline-block;
  position: relative;
}

#bBasket .bRow > .bCol.article .image {
  margin-right: 1em;
  display: block;
  float: left;
  width: 40px;
}

#bBasket .bRow.bTotal {
  color: $color-text;
  font-size: 1.3em;
  font-weight: bold;
  //background: $color-bg;
}

.loyaltyPointSpend {
  background-color: $color-bg;
}

#bBasket .bRow.sub {
  font-size: 1em;
}

#bBasket .bRow.sub .bCol > .inner {
  padding-bottom: 0.5em;
}

#bBasket .bRow.bTotal.last .bCol > .inner {
  padding: 1em 0;
}

#bBasket .bRow.bHead {
  background: #f1f1f1;
  color: $color-text;
  font-weight: bold !important;
}

#bBasket .bRow.bHead.titlePayment {
  margin-bottom: 0;
}

#bBasket .bRow.bHead > .bCol.total {
  font-size: 1em;
}

#bBasket #bTable a.light {
  color: $color-link;
  text-decoration: none;
}

#bBasket #bTable a.light:hover {
  color: $color-link;
  text-decoration: underline;
}

#bBasket #bTable.addressBook a.light, #bBasket #bTable.chooseAddress a.light {
  margin: 0.5em 0;
  display: inline-block;
}

#bBasket #bTable .bCol.address.bInvoice:after {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 0;
  top: 8px;
  line-height: 1em;
  content: "\e811";
  text-indent: 0;
  font-size: 1.8em;
  color: $color-text;
}

#bBasket #bTable .bCol.address.bDelivery:after {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 0;
  top: 8px;
  line-height: 1em;
  content: "\e830";
  text-indent: 0;
  font-size: 1.8em;
  color: $color-text;
}

#bBasket .bOption {
  position: relative;
  display: block;
}

#bBasket .bOption .cRadio {
  width: 70px;
  height: 100%;
  position: absolute;
}

#bBasket .bOption .cRadio .radio {
  position: absolute;
  top: 50%;
  left: 10%;
  height: 13px;
  width: 13px;
  margin-top: -7px;
}

#bBasket .bOption .cInfo {
  float: left;
  margin-left: 70px;
  width: 170px;
  padding: 15px 0;
}

#bBasket .bOption .cChoices {
  margin-left: 240px;
  position: absolute;
  height: 100%;
  width: 200px;
}

#bBasket .bOption .cChoices .choices {
  position: absolute;
  top: 50%;
  left: 0;
  height: 26px;
  width: 100%;
  margin-top: -13px;
}

#bSteps ul li:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -20px;
  border-width: 20px 0 20px 10px;
  border-style: solid;
  border-color: transparent transparent transparent $color-white;
  left: 0;
  z-index: 0;
}

#bBasket .bOption.active {
  background: $color-bg;
}

#bBasket .bOption .choices {
  display: none;
}

#bBasket .bOption.active .choices {
  display: block;
  background-color: $color-white !important;
  line-height: 40px;
  min-height: 40px;
}

#bBasket .bRow.bConditions .bCol {
  margin: 0;
}

#bBasket .bRow.bConditions .bCol > .inner {
  margin-bottom: 1em;
}

#bBasket .bRow.accessory.line {
  &:last-of-type {
    border-bottom: 1px dotted #c1c2c4;
    padding-bottom: 1px;
  }
}

#bBasket .bRow.accessory > .bCol.article {
  background-clip: padding-box;
  background-color: $color-white;
  border-top: 1px solid #ddd;
  clear: both;
  display: inline-block;
  position: relative;
  margin-bottom: -1px;
}

#bBasket .bRow.accessory > .bCol.article > .inner {
  position: relative;
  padding: 4px 0 4px 40px;
}

#bBasket .bRow.accessory > .bCol.article > .inner:before {
  background-clip: padding-box;
  color: #34495e;
  display: block;
  font-size: 17px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  left: 12px;
  top: 50%;
  margin-top: -10px;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e828";
}

#bBasket .bRow.accessory > .bCol.article > .inner > .article {
  float: none !important;
}

#bBasket .textLines .bRow > .bCol > .inner {
  padding: 0;
}

#bBasket .textLines .bRow:first-child > .bCol > .inner {
  padding-top: 8px;
}

#bBasket .textLines .bRow:last-child > .bCol > .inner {
  padding-bottom: 8px;
}

#bBasket .bRow.bPayment {
  margin-bottom: 1em;
}

#bBasket .bRow.discount {
  position: relative;
  background: $color-bg;
}

#bBasket .bRow.discount span.title {
  margin-right: 8px;
}

#bBasket .bRow.discount .btnIcon {
  margin-left: 0;
}

#bBasket .bRow.discount input.text {
  width: 82%;
  float: left;
}

#bBasket .bRow.discount button.addCoupon {
  min-height: 40px;
  height: 40px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  width: 9%;
  padding: 0px !important;
  float: right;
}

#bBasket .bRow.discount.addCoupon {
  background: none;
  margin-bottom: 1em;
}

#bBasket .bRow.discount.addCoupon .info > .inner {
  padding: 14px 8px;
}

#bBasket .discountBox {
  border: 1px solid $color-bg;
  background: $color-white;
  float: left;
  text-align: left;
  display: inline-block;
}

#bBasket .discountBox > .inner {
  padding: 10px !important;
}

#bBasket .discountBox .title {
  background: $color-text;
  color: $color-bg;
  font-weight: bold !important;
  margin-bottom: 5px;
  padding: 8px;
}

#bBasket .discountBox .text {
  width: 100%;
  margin-bottom: 10px;
}

#bBasket .bRow .bCol {
  float: left;
  position: relative;
  padding: 0 1%;
  width: 98%;
}

#bBasket .bRow.orderLine a {
  line-height: 1.125em;
}

#bBasket .bRow > .bCol.value .bCol.first,
#bBasket .bRow > .bCol.value .bCol:first-child {
  padding-left: 0;
}

#bBasket .bRow > .bCol.full {
  width: 98%;
}

#bBasket .bRow > .bCol.number {
  width: 13%;
}

#bBasket .bRow > .bCol.date {
  width: 23%;
}

#bBasket .bRow > .bCol.ordertotal {
  width: 13%;
  text-align: right;
}

#bBasket .bRow > .bCol.status {
  width: 23%;
}

#bBasket .bRow > .bCol.description {
  width: 52%;
}

#bBasket .bRow > .bCol.mutation {
  width: 23%;
}

#bBasket .bRow > .bCol.one {
  width: 100px;
}

#bBasket .bRow > .bCol.title {
  width: 23%;
  text-align: right;
}

#bBasket .bRow > .bCol.value {
  width: 73%;
}

#bBasket .bRow > .bCol.value .bCol {
  width: 48%;
}

#bBasket .bRow > .bCol.value .bCol.full {
  width: 100%;
  padding: 0;
}

#bBasket .bRow > .bCol.value .bCol .text {
  width: 100%;
}

#bBasket .bRow > .bCol.value .bCol #street_number {
  width: 100px;
}

#bBasket .bRow > .bCol.address,
#bBasket .bRow > .bCol.settings {
  width: 48%;
}

#bBasket .bRow > .bCol.option {
  width: 23%;
  text-align: center;
  padding: 5px;
}

#bBasket .bRow > .bCol.article,
#bBasket .bRow > .bCol.info {
  width: 58%;
}

#bBasket .bRow > .bCol.adjust {
  width: 18%;
}

#bBasket .bRow > .bCol.total {
  width: 18%;
  text-align: right;
  font-weight: bold;
  font-size: 1.1em;
  float: right;
}

#bBasket .bRow.bFooter {
  border-top: 1px dotted #c1c2c4;
}

#bBasket #bTable.downloads .bRow > .bCol.number {
  width: 18%;
}

#bBasket #bTable.downloads .bRow > .bCol.download {
  width: 38%;
}

#bBasket #bTable.downloads .bRow > .bCol.info {
  width: 38%;
}

/**********************************************
/* SHOP: QUICKORDER
/*********************************************/
#quickOrderRows .row {
  margin: 0;
}

#quickOrderRows .head {
  background: #343434;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5em;
}

#quickOrderRows .head .col > .inner {
  padding: 8px;
}

#quickOrderRows .appendRow .col > .inner {
  padding: 8px 0;
}

#quickOrderRows .button {
  padding: 0 10px;
  margin: 0;
}

#quickOrderRows .last {
  float: right;
}

#quickOrderRows .col.remove {
  text-align: right;
}

#quickOrderRows .select2-container .select2-choice {
  height: 35px;
  line-height: 35px;
}

#quickOrderRows .select2-container .select2-choice .select2-arrow b {
  background-position: 0 4px;
}

#quickOrderRows .select2-container,
#quickOrderRows .select2-offscreen {
  width: 100% !important;
}

#quickOrderRows a.remove {
  white-space: nowrap;
  display: inline-block;
  height: 25px;
  width: 25px;
  line-height: 1em;
  margin: 0 0 0 5px;
  outline: 0;
  -wegbkit-tap-highlight-color: transparent;
  cursor: pointer;
  text-align: center;
  border: none;
  speak: none;
  color: #4d4d4d;
  position: relative;
  overflow: hidden;
  text-indent: 9999px;
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-border-radius: 0.25em;
  -webkit-border-radius: 0.25em;
  border-radius: 0.25em;
  background-color: $color-bg;
  background: -moz-linear-gradient(top, $color-bg 0%, #cccccc 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $color-bg), color-stop(100%, #cccccc));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $color-bg 0%, #cccccc 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $color-bg 0%, #cccccc 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $color-bg 0%, #cccccc 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, $color-bg 0%, #cccccc 100%);
  /* W3C */
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

#quickOrderRows a.remove:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  line-height: 25px;
  content: "\e80d";
  text-indent: 0;
  font-size: 1.2em;
}

#quickOrderRows a.remove:hover {
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
  color: $color-text;
}

#quickOrderRows .col {
  float: left;
  margin-left: 2%;
}

#quickOrderRows .col.first {
  margin-left: 0;
}

#quickOrderRows .col.amount {
  width: 15.0%;
}

#quickOrderRows .col.article {
  width: 75%;
}

#quickOrderRows .col.remove {
  width: 6%;
}

/**********************************************
/* SHOP: STEPS
/*********************************************/
#bSteps {
  margin-bottom: 20px;
  clear: both;
}

#bSteps ul {
  position: relative;
  height: 40px;
  line-height: 40px;
  list-style: none;
  margin: 0 !important;
  padding: 0;
}

#bSteps ul li {
  display: block;
  float: left;
  height: inherit;
  margin: 0 0.8% 0 0;
  padding: 0;
  background: $color-bg;
  width: 19.2%;
  text-align: center;
}

#bSteps ul li {
  background: $color-bg;
  float: left;
  color: $color-text;
  position: relative;
  height: inherit;
}

#bSteps ul li:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -20px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 10px solid $color-bg;
  right: -10px;
  z-index: 1;
}

#bSteps ul li.current {
  background: $color-primary;
  color: $color-white !important;
}

#bSteps ul li.current strong {
  color: $color-white;
}

#bSteps ul li.current:after {
  border-left-color: $color-primary;
  border-left-color: $color-primary;
}

#bSteps ul li:first-child:before {
  border: none;
}

#bSteps ul li:last-child {
  margin-right: 0;
}

#bSteps ul li:last-child:after {
  border: none;
}

/*  Gridpak Basket
/* -------------------------------------------------------------------- */
.bCol {
  border: 0px solid transparent;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding-box !important;
  -webkit-background-clip: padding-box !important;
  background-clip: padding-box !important;
}

.bCol.last {
  float: right;
}

.bRow {
  clear: both;
}

.bRow:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}

/* 1. 4 Column Grid 0px - 319px
-----------------------------------------------------------------------------
Span 1:    22.0%
Span 2:    48.0%
Span 3:    74.0%
Span 4:    100%
----------------------------------------------------------------------------- */
@media screen and (min-width: 0px) and (max-width: 479px) {
  #bBasket .bRow .bCol {
    margin-left: 4%;
    padding: 0;
  }
  #bBasket .bRow .bCol.first {
    margin-left: 0;
  }
  #bBasket .bRow > .bCol > .inner,
  #bBasket .bRow > .bCol.title > .inner,
  #bBasket #bTable.orders .inner,
  #bBasket #bTable.downloads .inner,
  #bBasket .inner_1 {
    padding: 0 0 8px 0;
  }
  #bBasket .bCol.editAddress > .inner,
  #bBasket .inner_2 {
    padding: 0 0 8px 0;
  }
  #bBasket #bTable.addressBook > .bRow.line,
  #bBasket .inner_3 {
    padding: 8px;
  }
  #bBasket .bRow > .bCol.address.bInvoice > .inner,
  #bBasket .bRow > .bCol.address.bDelivery > .inner,
  #bBasket .inner_4 {
    padding: 0 0 8px 0;
  }
  #bBasket .bRow > .bCol.address > .inner,
  #bBasket .bRow > .bCol.option > .inner,
  #bBasket .inner_5 {
    padding: 0;
  }
  #bBasket .span_1 {
    width: 22.0%;
  }
  #bBasket .bRow > .bCol.adjust,
  #bBasket .bRow > .bCol.total,
  #bBasket #bTable.downloads .bRow > .bCol.date,
  #bBasket #bTable.downloads .bRow > .bCol.mutation,
  #bBasket .span_2 {
    width: 48.0%;
  }
  #bBasket .span_3 {
    width: 74.0%;
  }
  #bBasket .bRow > .bCol.full,
  #bBasket .bRow > .bCol.info,
  #bBasket .bRow.addCoupon > .bCol.info,
  #bBasket .bRow.addCoupon > .bCol.coupon,
  #bBasket .bRow > .bCol.title,
  #bBasket .bRow > .bCol.value,
  #bBasket .bRow > .bCol.value .bCol,
  #bBasket .bRow > .bCol.article,
  #bBasket .bRow.destRow > .bCol,
  #bBasket .bRow > .bCol.address,
  #bBasket .bRow > .bCol.settings,
  #bBasket .bRow > .bCol.option,
  #bBasket .bRow > .bCol.number,
  #bBasket .bRow > .bCol.date,
  #bBasket .bRow > .bCol.ordertotal,
  #bBasket .bRow > .bCol.status,
  #bBasket #bTable.downloads .bRow > .bCol,
  #bBasket #bTable.giftcards .bRow > .bCol,
  #bBasket .span_4 {
    margin-left: 0;
    width: 100%;
  }
  #bBasket .bRow {
    padding: 0;
  }
  #bBasket .bRow.bHead {
    margin-left: -10px;
    margin-right: -10px;
    webkit-border-radius: 0;
    border-radius: 0;
    margin-bottom: 20px;
  }
  #bBasket .alignRight {
    text-align: left;
  }
  #bBasket .btnIcon.icomoon,
  #bBasket .btnIcon.fa {
    height: 30px;
    width: 30px;
  }
  #bBasket .btnIcon.btnAddLine {
    margin-left: 0;
  }
  #bBasket .btnIcon.btnUpdateLine {
    margin-left: 0;
  }
  #bBasket .btnIcon.btnRemoveLine {
    position: absolute;
    right: 10px;
    top: 10px;
    margin-left: 0;
  }
  #bBasket #bTable.orders .bRow,
  #bBasket #bTable.downloads .bRow {
    margin-left: -20px;
    margin-right: -20px;
  }
  #bBasket #bTable.orders .bRow.bHead,
  #bBasket #bTable.addressBook .bRow.bHead,
  #bBasket #bTable.downloads .bRow.bHead {
    margin-bottom: 0;
  }
  #bBasket #bTable.shoppingcart .bHead,
  #bBasket #bTable.orders .bRow.bHead .bCol.date,
  #bBasket #bTable.orders .bRow.bHead .bCol.ordertotal,
  #bBasket #bTable.orders .bRow.bHead .bCol.status,
  #bBasket #bTable.giftcards .bRow > .bCol.adjust,
  #bBasket #bTable .bHead.titleOrderAddress {
    display: none;
  }
  #bBasket #bTable.giftcards .vouchercode {
    width: 90%;
    text-overflow: ellipsis;
  }
  #bBasket .bRow.bHead > .bCol > .inner {
    padding: 8px 20px !important;
  }
  #bBasket #bTable.shoppingcart {
    border: 1px solid $color-bg;
    background: $color-white;
    webkit-border-radius: 0.25em;
    border-radius: 0.25em;
  }
  #bBasket #bTable.shoppingcart .bCol {
    position: static;
  }
  #bBasket #bTable.shoppingcart .bCol.adjust {
    margin-left: 0;
    text-align: left;
  }
  #bBasket #bTable.shoppingcart .bRow > .bCol > .inner {
    padding: 10px;
  }
  #bBasket #bTable.orders .bCol.ordertotal {
    text-align: left;
  }
  #bBasket #bTable.orders .bRow.clearfix,
  #bBasket #bTable.downloads .bRow.clearfix {
    border-bottom: 1px dotted #c1c2c4;
  }
  #bBasket #bTable .bRow.orderLine {
    position: relative;
  }
  #bBasket #bTable .bRow.orderLine a {
    text-decoration: none;
  }
  #bBasket #bTable .bRow.orderLine .title {
    margin-right: 30px;
    min-height: 30px;
    margin-bottom: 10px;
  }
  #bBasket #bTable .bRow.orderLine .image {
    display: block;
    margin: 0 0 5px 0;
    float: none;
  }
  #bBasket #bTable .bRow.orderLine > .bCol.adjust {
    min-height: 50px;
  }
  #bBasket #bTable.shoppingcart .bCol.adjust > .inner {
    padding-right: 0;
  }
  #bBasket #bTable.shoppingcart .bCol.total > .inner {
    padding-left: 0;
  }
  #bBasket #bTable.shoppingcart .destRow > .bCol > .inner {
    padding: 10px 10px 0 10px;
  }
  #bBasket #bTable.shoppingcart .bRow.destRow .info {
    display: block;
  }
  #bBasket #bTable .bRow.orderLine > .bCol.adjust .text.amount {
    width: 50px;
    height: 30px;
    text-align: left;
    text-indent: 4px;
  }
  #bBasket #bTable.shoppingcart .bRow .info,
  #bBasket #bTable.downloads .bRow > .bCol.order {
    display: none;
  }
  #bBasket #bTable .bRow.discount > .bCol.info {
    display: inline-block;
  }
  #bBasket #bTable.shoppingcart .bRow.giftcards .adjust,
  #bBasket #bTable.shoppingcart .bRow.giftcards .total {
    display: none;
  }
  #bBasket #bTable.shoppingcart .bRow.giftcards .info {
    display: block;
  }
  #bBasket #bTable .bRow.bTotal {
    background: $color-bg;
    border-top: 1px solid $color-bg;
    border-bottom: 1px solid $color-bg;
    padding: 0;
  }
  #bBasket .bRow > .bCol.option {
    text-align: left;
  }
  #bBasket #bTable .bOption .costs {
    display: block !important;
    margin: 0 !important;
  }
  #bBasket #bTable .bOption .cInfo {
    width: auto !important;
  }
  #bBasket #bTable .bOption .cChoices {
    position: static;
    width: auto;
    margin-left: 70px;
    padding-right: 1em;
  }
  #bBasket #bTable .bOption .cChoices select.choices {
    position: static;
    margin: 0 0 1em 0;
  }
  #bBasket #bTable .bCol.address.bInvoice:after,
  #bBasket #bTable .bCol.address.bDelivery:after {
    display: block;
  }
  #bBasket .bRow > .bCol.title {
    font-weight: bold;
    text-align: left;
  }
  #bBasket .bRow.bConditions .bCol {
    margin: 0;
  }
  #bBasket .bRow.bConditions .bCol > .inner {
    padding: 0;
    margin-bottom: 1em;
  }
  #bBasket .bRow > .bCol input.text {
    width: 100%;
    margin-bottom: 5px;
    webkit-border-radius: 0 m;
    border-radius: 0;
  }
  #bBasket .bRow > .bCol textarea {
    height: 100px;
  }
  #bBasket .bRow > .bCol select,
  #bBasket .bOption .cChoices select.choices {
    width: 100%;
    webkit-border-radius: 0.25em;
    border-radius: 0.25em;
  }
  #bBasket .bRow > .bCol select.destination {
    margin-bottom: 0;
  }
  #bBasket .bRow > .bCol textarea {
    width: 100%;
    webkit-border-radius: 0.25em;
    border-radius: 0.25em;
  }
  #bBasket div.lightColor {
    padding: 10px 10px 15px;
    margin: 10px 0;
  }
  #bBasket .button.offer,
  #bBasket a.button.offer {
    margin: 0;
  }
  #bBasket .button.offer,
  #bBasket a.button.offer,
  #bBasket .submit {
    margin-bottom: 5px !important;
  }
  #bBasket .button,
  #bBasket .button:hover,
  #bBasket .button.back,
  #bBasket .button.back:hover,
  #bBasket .submit {
    float: none;
    min-height: 45px;
    line-height: 45px;
    margin-left: 0;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none !important;
  }
  #bBasket .bRow.discount .button.addCoupon {
    width: 25%;
  }
  #bBasket .bRow.accessory > .bCol.article > .inner:before {
    display: none;
  }
  #bBasket .bRow.accessory > .bCol.article .image {
    width: 100%;
  }

  #bSteps .text {
    display: none;
  }

  #quickOrderRows .head .col.amount, #quickOrderRows .head .col.remove {
    display: none;
  }
  #quickOrderRows .head .col.article {
    width: 100%;
    margin: 0;
  }
  #quickOrderRows .appendRow .col.article {
    width: 70%;
  }
  #quickOrderRows .appendRow .col.remove {
    width: 11%;
  }
  #quickOrderRows .button,
  #quickOrderRows .button:hover {
    float: none;
    height: auto;
    line-height: 1.2em;
    margin: 0 0 5px 0 !important;
    padding: 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  #quickOrderRows .button:before,
  #quickOrderRows .button:hover:before {
    display: none;
  }
}

/* 3. 8 Column Grid 480px - 768px
-----------------------------------------------------------------------------
Span 1:    9.875%
Span 2:    22.75%
Span 3:    35.625%
Span 4:    48.5%
Span 5:    61.375%
Span 6:    74.25%
Span 7:    87.125%
Span 8:    100%
----------------------------------------------------------------------------- */
@media screen and (min-width: 480px) and (max-width: 768px) {
  #bBasket .bRow .bCol {
    margin-left: 3%;
    padding: 0;
  }
  #bBasket .bRow .bCol.first {
    margin-left: 0;
  }
  #bBasket .mobile {
    display: block;
  }
  #bBasket img {
    max-width: 100%;
    height: auto !important;
  }
  #bBasket .bRow > .bCol.title,
  #bBasket .bRow > .bCol.adjust,
  #bBasket .bRow > .bCol.total,
  #bBasket .bRow > .bCol.option,
  #bBasket .bRow > .bCol.number,
  #bBasket .bRow > .bCol.ordertotal,
  #bBasket .bRow > .bCol.date,
  #bBasket .bRow > .bCol.status,
  #bBasket #bTable.downloads .bRow > .bCol.mutation,
  #bBasket .span_2 {
    width: 22.75%;
  }
  #bBasket #bTable.downloads .bRow > .bCol.date,
  #bBasket #bTable.downloads .bRow > .bCol.order,
  #bBasket .bRow.addCoupon > .bCol.info,
  #bBasket .span_3 {
    width: 35.625%;
  }
  #bBasket .bRow > .bCol.value .bCol,
  #bBasket .bRow > .bCol.article,
  #bBasket .bRow > .bCol.info,
  #bBasket .bRow > .bCol.address,
  #bBasket .bRow > .bCol.settings,
  #bBasket .bRow.discount > .bCol.adjust,
  #bBasket .span_4 {
    width: 48.5%;
  }
  #bBasket .bRow.addCoupon > .bCol.coupon,
  #bBasket .span_5 {
    width: 61.375%;
  }
  #bBasket .bCol.value,
  #bBasket .span_6 {
    width: 74.25%;
  }
  #bBasket .span_7 {
    width: 87.125%;
  }
  #bBasket .bRow > .bCol.value .bCol.full,
  #bBasket .bRow > .bCol.full,
  #bBasket .passRow > .bCol.value > .bCol,
  #bBasket #bTable.bOther .bCol.value > .bCol,
  #bBasket .bRow.accessory > .bCol.article,
  #bBasket .span_8 {
    margin-left: 0;
    width: 100%;
  }
  #bBasket .bRow > .bCol.adjust .text.amount {
    width: 30%;
  }
  #bBasket .giftcards input.text {
    width: 80%;
  }
  #bBasket .bRow.addCoupon > .bCol.info {
    text-align: left;
  }
  #bBasket .bRow.addCoupon > .bCol.info > .inner {
    padding: 8px;
  }
  #bBasket .bRow.discount > .bCol.total {
    clear: both;
  }
  #bBasket .bRow.discount > .bCol.adjust .btnIcon {
    float: right;
  }
  #bBasket #bTable .bRow.orderLine > .bCol.article .image {
    display: block;
    margin: 0 0 5px 0;
    float: none;
  }
  #bBasket #bTable .bOption .costs {
    display: block !important;
    margin: 0 !important;
  }
  #bBasket #bTable .bOption .cInfo {
    width: auto !important;
  }
  #bBasket #bTable .bOption .cChoices {
    position: static;
    width: auto;
    margin-left: 70px;
    padding-right: 1em;
  }
  #bBasket #bTable .bOption .cChoices select.choices {
    position: static;
    margin: 0 0 1em 0;
  }
  #bBasket .bRow.bConditions .bCol {
    margin: 0;
  }
  #bBasket .bRow.bConditions .bCol > .inner {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 1em;
  }
  #bBasket #bTable .bRow.accessory > .bCol.article .image {
    float: left;
    width: auto;
    margin: 0 0.5em 0 0;
  }

  #bSteps .text {
    display: none;
  }
}

/* 16 Column Grid - Infinity
-----------------------------------------------------------------------------
Span 1:    5.3125%
Span 2:    11.625%
Span 3:    17.9375%
Span 4:    24.25%
Span 5:    30.5625%
Span 6:    36.875%
Span 7:    43.1875%
Span 8:    49.5%
Span 9:    55.8125%
Span 10:    62.125%
Span 11:    68.4375%
Span 12:    74.75%
Span 13:    81.0625%
Span 14:    87.375%
Span 15:    93.6875%
Span 16:    100%
----------------------------------------------------------------------------- */

@media screen and (min-width: 768px) {
  #bBasket .bRow .bCol {
    margin-left: 1.5%;
    padding: 0;
  }
  #bBasket .bRow .bCol.first {
    margin-left: 0;
  }
  #bBasket .span_1 {
    width: 4.84375%;
  }
  #bBasket .span_2 {
    width: 11.1875%;
  }
  #bBasket .bRow > .bCol.total,
  #bBasket .bRow > .bCol.number,
  #bBasket .bRow > .bCol.ordertotal,
  #bBasket .span_3 {
    width: 17.53125%;
  }
  #bBasket .bRow > .bCol.title,
  #bBasket .bRow > .bCol.option,
  #bBasket .bRow > .bCol.adjust,
  #bBasket #bTable.downloads .bRow > .bCol.mutation,
  #bBasket .span_4 {
    width: 23.875%;
  }
  #bBasket .bRow > .bCol.date,
  #bBasket .bRow > .bCol.status,
  #bBasket #bTable.downloads .bRow > .bCol,
  #bBasket #bTable.downloads .bRow > .bCol.date,
  #bBasket .span_5 {
    width: 30.21875%;
  }
  #bBasket #bTable.downloads .bRow > .bCol.order,
  #bBasket .span_6 {
    width: 36.5625%;
  }
  #bBasket .bRow > .bCol.coupon,
  #bBasket .span_7 {
    width: 42.90625%;
  }
  #bBasket .bRow > .bCol.value .bCol,
  #bBasket .bRow > bCol.address,
  #bBasket .bRow > .bCol.settings,
  #bBasket .span_8 {
    width: 49.25%;
  }
  #bBasket .bRow > .bCol.article,
  #bBasket .bRow > .bCol.info,
  #bBasket .span_9 {
    width: 55.59375%;
  }
  #bBasket .span_10 {
    width: 61.9375%;
  }
  #bBasket .span_11 {
    width: 68.28125%;
  }
  #bBasket .bCol.value,
  #bBasket .span_12 {
    width: 74.625%;
  }
  #bBasket .span_13 {
    width: 80.96875%;
  }
  #bBasket .span_14 {
    width: 87.3125%;
  }
  #bBasket .span_15 {
    width: 93.65625%;
  }
  #bBasket .bRow > .bCol.value .bCol.full,
  #bBasket .bRow > .bCol.full,
  #bBasket .bRow.bConditions > .bCol,
  #bBasket .bCol.address.editAddress,
  #bBasket .span_16 {
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 575px) {
  #bBasket .bRow > .bCol.adjust .text.amount {
    margin: 0 0 5px 0;
    width: 90%;
    text-align: left;
    text-indent: 4px;
  }
  #bBasket .bRow.addCoupon .bCol.info {
    width: 100%;
    margin: 0;
    text-align: left;
  }
  #bBasket .bRow.addCoupon .bCol.info > .inner {
    padding-bottom: 0;
  }
  #bBasket .bRow.addCoupon .bCol.coupon {
    width: 100%;
    margin: 0;
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 990px) {
  #bBasket #bTable.orders .date,
  #bBasket #bTable.orders .ordertotal,
  #bBasket #bTable.orders .status {
    display: none;
  }
  #bBasket .bRow.accessory > .bCol.article {
    width: 100%;
  }
  #bBasket .bRow.discount span.title {
    display: block;
    margin-bottom: 8px;
  }
  #bBasket .bRow.addCoupon .bCol.info {
    width: 100%;
    text-align: left;
  }
  #bBasket .bRow.addCoupon .bCol.info > .inner {
    padding-bottom: 0;
  }
  #bBasket .bRow.addCoupon .bCol.coupon {
    width: 100%;
    margin: 0;
    text-align: left;
  }
}

/**
* 2) Element
*/

/**
* 3) Modifier
*/
