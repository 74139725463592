/**
 * Modules/buttons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */

.btn {
  display: inline-block;
  padding: $baseline/4 $baseline/2;
  background: none;
  border: none;
  text-decoration: none;
  outline: none;
  position: relative;
}

.button, #bBasket .button.back, html input[type=button], input[type=reset], input[type=submit], .content__col button:not(.cart__button, .button__cart__selected, .button__cart__grey) {
  background-color: $color-secondary;
  font-weight: $font-light;
  color: $color-white;
  font-size: 16px;
  border: 0;
  line-height: 45px;
  min-height: 45px;
  padding: 0 20px 0 20px;
  border-radius: 4px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  transition: all .3s ease;
  cursor: pointer;

  &.no-animation {
    .fa, .fab, .fal, .far, .fas {
      transition: all .3s ease;
      left: auto;
    }

    @include respond-min($md) {
      &:hover {

        .fa, .fab, .fal, .far, .fas {
          transition: all .3s ease;
          left: auto;
        }
      }
    }

  }

  .fa, .fab, .fal, .far, .fas {
    transition: all .3s ease;
    position: relative;
    margin-left: 5px;
    left: 0;
    font-size: 14px;
    font-weight: $font-light !important;
  }

  &.fullwidth {
    width: 100%;
  }

  @include respond-min($md) {
    &:hover {
      transition: all .3s ease;
      background-color: $color-text;
      color: $color-white;

      .fa, .fab, .fal, .far, .fas {
        transition: all .3s ease;
        position: relative;
        left: 10px;
      }
    }
  }

  &.lines {
    background-color: transparent !important;
    border: 1px solid $color-black !important;
    color: $color-black !important;
    transition: all .3s ease;
    line-height: 43px !important;
    min-height: 43px !important;

    @include respond-min($md) {
      &:hover {
        transition: all .3s ease;
        background-color: transparent !important;
        border: 1px solid $color-secondary !important;
        color: $color-secondary !important;
      }
    }
  }

  &.reverse {
    background-color: $color-text !important;
    transition: all .3s ease;
    color: $color-white !important;

    @include respond-min($md) {
      &:hover {
        transition: all .3s ease;
        background-color: $color-secondary !important;
      }
    }
  }
}

.menu-toggle {
  position: absolute;
  width: 60px;
  height: 50px;
  left: 5px;
  top: 8px;
  color: $color-black;
  display: block;
  transition: all .2s ease;

  @include respond-min($md) {
    top: 39px;
    right: 50px;
    cursor: pointer;
    display: none;

    &:hover {
      opacity: 0.7;
    }
  }

  .hamburger-box {
    z-index: -5;
    position: relative;
  }

  &.is-active {
    right: 15px;
    transition: all .2s ease;

    &:after {
      opacity: 0;
      transition: all .2s ease;
    }

    .hamburger-inner {
      &:before {
        top: -10px !important;
      }

      &:after {
        bottom: -10px !important;
      }
    }
  }

  .hamburger-inner {
    height: 1px !important;
    background-color: $color-black;

    &:before {
      background-color: $color-black;
      top: -16px !important;
      height: 1px !important;
    }

    &:after {
      background-color: $color-black;
      bottom: -16px !important;
      height: 1px !important;
    }
  }
}

.sub-toggle {
  width: 20%;
  max-height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  .fa-angle-up {
    display: none;
  }

  &.is-active .fa-angle-up {
    display: block;
  }

  &.is-active .fa-angle-down {
    display: none;
  }

  @include respond-min($md) {
    display: none;
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */

.btn--block {
  display: block;
}
