/**
 * Layout/content stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */

/**
 * 1) Block
 */


.notification {
  background-color: $color-primary;

  .inner {
    color: $color-white;
    font-size: 14px;
    position: relative;

    .close {
      width: 30px;

      @include respond-min($md) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
      }

      i {
        font-size: 20px;
        cursor: pointer;

        @include respond-min($md){
          &:hover{
            color: $color-black;
          }
        }
      }
    }

    .icon {
      width: 30px;

      i {
        font-size: 18px;
        cursor: pointer;
      }
    }

    .text {
      @include respond-max(480px) {
        width: calc(100% - 30px);
      }

      @include respond-min($xs) {
        width: calc(100% - 60px);
      }
    }

    a:not([class]) {
      text-decoration: underline;
      color: $color-white;

      @include respond-min($md) {
        &:hover {
          text-decoration: none;
        }
      }
    }

    a[href^="tel"] {
      color: $color-white;
    }

    p, ul {
      color: $color-white;

      & + h1 {
        margin-top: 5px !important;
      }

      & + h2 {
        margin-top: 5px !important;
      }

      & + h3 {
        margin-top: 5px !important;
      }

      & + strong {
        margin-top: 5px !important;
        display: inline-block;
      }
    }

    h1, h2, h3, h4, h5, h6, p {
      margin-bottom: 5px;
    }

    h1, h2, h3, h4, h5, h6 {
      color: $color-white;
      font-size: 18px;
    }

    ul {
      margin-left: $baseline;
      margin-bottom: $baseline;
      padding-left: 0;
      position: relative;
      list-style: none !important;

      li {
        position: relative;
        list-style: none !important;

        &:before {
          content: "\f111";
          font-family: "Font Awesome 5 Pro";
          font-weight: 700;
          font-size: 6px;
          color: $color-white;
          position: absolute;
          left: -20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      ul {
        margin-top: 0;
        margin-bottom: 0;
        list-style: none;

        li {
          position: relative;
          list-style: none !important;

          &:before {
            content: "\f111";
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            font-size: 6px;
            color: $color-white;
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    ol {
      margin-left: $baseline;
      margin-bottom: $baseline;
      padding-left: 0;
      position: relative;
      list-style: none !important;

      > li {
        position: relative;
        list-style: none !important;
        counter-increment: counter;

        &:before {
          content: counter(counter);
          font-weight: $font-light;
          font-size: 14px;
          color: $color-white;
          position: absolute;
          left: -20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      ol {
        margin-top: 0;
        margin-bottom: 0;

        li {
          position: relative;
          list-style: none !important;
          counter-increment: counter2;

          &:before {
            content: counter(counter2);
            font-weight: $font-light;
            font-size: 14px;
            color: $color-white;
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    ul {
      margin-left: 25px;
      list-style: disc !important;

      ul {
        list-style: circle !important;
        margin-left: 15px;
        margin-bottom: 10px;
      }

      & + * {
        margin-top: 20px;
      }
    }

    hr {
      border-color: 1px solid $color-white;
      margin-top: 20px;
    }

    *:last-child {
      margin-bottom: 0 !important;
    }
  }
}

/**
 * 2) Element
 */

/**
 * 3) Modifier
 */
