/**
 * Modules/media stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */



/**
 * 1) Block
 */

.js-img, .js-nav-merge, .nav, .hero--img, .hero, .cookie, .js-gallery, .gallery--nav, .preview, .faq, .floating-label, .notification {
    display: block;

    .no-jquery & {
        display: none;
    }
}



/**
 * 2) Element
 */



/**
 * 3) Modifier
 */
