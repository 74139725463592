/**
 * Webshop/filters stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */
/**
 * 1) Block
 */


/**
 * 2) Element
 */


.loyalty__products {
  [data-title] {
    position: relative;
    cursor: help;
  }

  [data-title]:hover::before {
    content: attr(data-title);
    padding: 10px;
    color: $color-text;
    left: 0px;
    position: absolute;
    text-align: left;
    overflow: hidden;
    bottom: 38px;
    width: 230px;
    min-width: 180px;
    font-size: 12px;
    display: block;
    z-index: 2000000;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    background: $color-white;
    font-weight: $font-light;
    line-height: 20px;
  }

  [data-title]:hover::after {
    content: '';
    width: 0;
    z-index: 99999999;
    height: 0;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: $color-white transparent transparent transparent;
    position: absolute;
    bottom: 28px;
    left: 20px;
  }

  .loyalty__points {
    margin: 0 0 10px 0;
    font-weight: 600;
    color: $color-text;
    font-size: 13px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;

    i {
      margin-right: 5px;
      font-size: 18px;
      color: $color-green;
    }
  }
}


.products, #bBasket .cms-shop-articles-inner {

  .product__item {
    text-decoration: none;

    .message_addedtocart {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      color: $color-green;
      visibility: hidden;
      z-index: 11;
      width: 100%;
      padding: 20px;
      transition: all .3s ease;

      span {
        font-size: 16px;
        font-weight: $font-bold;
        display: inline-block;
      }

      .fa, .fab, .fal, .far, .fas {
        font-size: 22px;
        display: inline-block;
      }
    }

    &.addedtocart {
      .inner {

        .message_addedtocart {
          visibility: visible;
          transition: all .3s ease;
        }

        &:before {
          transition: all .3s ease;
          visibility: visible;
        }
      }
    }

    .inner {
      padding: 20px;
      background-color: $color-white;
      box-shadow: 0 0 10px 0 rgba($color-black, .1);
      overflow: hidden;
      position: relative;

      &:before {
        content: "";
        background: rgba($color-white, .95);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        transition: all .3s ease;
        height: 100%;
        width: 100%;
        z-index: 10;
        visibility: hidden;
      }
    }

    .buttons {

      .favorite {
        display: inline-block;
        margin-left: 5px;
        float: right;
        margin-top: 0;

        a {
          text-decoration: none;
        }

        .fa {
          font-weight: 600;
          color: darken($color-bg, 10%);
          z-index: 4;
          font-size: 20px;
          background-color: $color-bg;
          height: 35px;
          line-height: 35px;
          width: 35px;
          border-radius: 100%;
          display: block;
          text-align: center;

          &.active {
            color: $color-secondary;
            font-weight: 600;
            background-color: lighten($color-secondary, 30%);
          }

          @include respond-min($md) {
            &:hover {
              color: lighten($color-secondary, 30%);
              font-weight: 600;
              background-color: $color-secondary;
            }
          }
        }
      }

      .button {
        width: 100%;
        display: inline-block;
        border-radius: 4px !important;
        line-height: 35px !important;
        min-height: 35px !important;
        font-size: 16px !important;

        &.loggedin {
          display: inline-block;
          width: calc(100% - 40px);
        }

        @include respond-max(480px) {
          font-size: 13px;
          line-height: 35px;
          min-height: 35px;
          margin-top: 0 !important;
        }
      }
    }


    .info {

      @include respond-max(480px) {
        width: calc(66% - 30px);
        padding-left: 10px;
        float: left;
      }

      h2 {
        font-size: 18px;
        color: $color-black;
        font-weight: $font-regular;
        margin-bottom: 3px;

        @include respond-max(480px) {
          font-size: 14px;
        }
      }

      .prices {
        .price {
          font-weight: $font-black;
          color: $color-green;
          font-size: 22px;
          float: left;

          @include respond-max(480px) {
            font-size: 16px;
          }

          span.vat {
            text-transform: lowercase !important;
          }
        }

        .oldprice {
          position: relative;
          margin-right: 10px;
          font-weight: $font-regular;
          font-size: 18px;
          color: $color-grey;
          text-decoration: line-through;
          float: left;
          top: 3px;

          @include respond-max(480px) {
            font-size: 16px;
            top: 0px;
          }
        }
      }

    }

    @include respond-min($md) {
      &:hover {
        .image {
          -webkit-transform: scale(1.05);
          -ms-transform: scale(1.05);
          transform: scale(1.05);
          position: relative;
          transition: all .3s ease;
        }
      }
    }

    .label {
      position: absolute;
      top: 20px;
      left: 10px;
      font-size: 15px;
      padding: 6px 10px;
      background-color: $color-primary;
      border-radius: 0 4px 4px 0;
      color: $color-white;
      z-index: 15;

      @include respond-max(480px) {
        left: auto;
        border-radius: 4px 0 0 4px;
        right: 10px;
        font-size: 12px;
        padding: 4px 8px;
      }

      &.sale {
        background-color: $color-secondary;
        left: auto;
        right: 10px;
        border-radius: 4px 0 0 4px;
      }
    }

    .zoom {
      overflow: hidden;
      padding: 0px;
      border-bottom: 1px solid $color-bg;
      position: relative;

      .favorite {
        .fa {
          font-weight: $font-regular;
          color: $color-green;
          position: absolute;
          top: 5px;
          left: 5px;
          z-index: 4;
          font-size: 22px;

          &.active {
            color: $color-green;
            font-weight: 600;
          }

          @include respond-min($md) {
            &:hover {
              color: $color-primary;
            }
          }
        }
      }

      @include respond-max(480px) {
        width: 33%;
        float: left;
      }

    }

    .image {
      transition: all .3s ease;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      padding-bottom: 75%;
      position: relative;
    }
  }

}

#bBasket .cms-shop-articles-inner {
  margin-left: -10px;
  margin-right: -10px;
}

.info.message, .bMessage {
  background: #d5eed4;
  border-radius: 0;
  text-align: center;
  padding: 10px 20px;
  color: #48b543;
  margin-bottom: 20px;
  margin-top: 20px;
}

/**
 * 3) Modifier
 */
