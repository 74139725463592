/**
 * Modules/icons stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */


/**
 * 1) Block
 */

.payments {
  background-color: $color-bg;

  ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-self: center;
    min-height: 40px;

    @include respond-min($md){
      justify-content: space-between;
    }

    li {
      text-align: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;

      img {
        max-height: 44px;
        padding: 10px 5px;
      }

    }
  }
}


/**
 * 2) Element
 */


/**
 * 3) Modifier
 */
