/**
* Modules/icons stylesheet
*
* Table of contents
* 1) Block
* 2) Element
* 3) Modifier
*/

/**
* 1) Block
*/

textarea, textarea.form-control {
  background: $color-bg;
  border-collapse: collapse;
  border: none;
  margin-bottom: 5px;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px !important;
  resize: vertical;
  min-height: 150px;
  pointer-events: auto !important;
}

::-ms-clear {
  display: none !important;
}

input[name='delivery[residence]']:disabled, input[name='delivery[street]']:disabled{
  background-color: $color-bg !important;
}

.change-address{
  display: none;
  font-size:12px;
  color: #4b50ff;
  margin: 5px;
  text-decoration: underline;
  text-align: right;
  &:hover{
    cursor: pointer;
  }

}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"] {
  -webkit-appearance: searchfield;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

select, input[type="text"], input[type="password"], input[type="email"], input.text, input.form-control, input[type="number"] {
  font-size: 16px !important;
  height: 45px;
  -webkit-appearance: none;
  border-radius: 4px;
  line-height: 45px;
  width: 100%;
  border-collapse: collapse;
  border: none;
  margin-bottom: 5px;
  padding: 0 15px;
  box-sizing: border-box;
  background: $color-bg;
  display: inline-block;
}

*::-webkit-input-placeholder, textarea, textarea.form-control, *::-webkit-input-textarea, -webkit-input-placeholder, input:-moz-placeholder {
  color: lighten($color-grey, 0%) !important;
}

::-webkit-input-placeholder {
  color: lighten($color-grey, 0%) !important;
  opacity: 1;
}

:-moz-placeholder { /* Firefox 18- */
  color: lighten($color-grey, 0%) !important;
  opacity: 1;
}

::-moz-placeholder { /* Firefox 19+ */
  color: lighten($color-grey, 0%) !important;
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
  color: lighten($color-grey, 0%) !important;
}

input[type="text"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder, input[type="search"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input.text::-webkit-input-placeholder, input.keywords::-webkit-input-placeholder, input.form-control::-webkit-input-placeholder {
  color: lighten($color-grey, 0%) !important;
}

#error {
  position: relative;
  padding: 10px;
  background: #faaea8;
  color: $color-white;
  margin: 10px 0;
  font-size: 14px;
  border-radius: 5px;
}

#success {
  position: relative;
  background: #add47f;
  padding: 10px;
  color: $color-white;
  margin: 10px 0;
  font-size: 14px;
  border-radius: 5px;
}

#item.error, input.error, textarea.error, select.error {
  border: 1px #f44336 solid !important;
}

textarea.error::-webkit-input-placeholder, input.form-control.error::-webkit-input-placeholder, input.keywords.error::-webkit-input-placeholder, input.text.error::-webkit-input-placeholder, input[type=email].error::-webkit-input-placeholder, input[type=password].error::-webkit-input-placeholder, input[type=search].error::-webkit-input-placeholder, input[type=text].error::-webkit-input-placeholder {
  color: #f44336 !important;
}

label.error {
  color: #f44336 !important;
}

input[type=checkbox], input[type=radio] {
  margin-right: 10px;
}

/**
* 2) Element
*/

/**
* 3) Modifier
*/
