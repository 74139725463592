/**
 * Webshop/cms-cart stylesheet
 *
 * Table of contents
 * 1) Block
 * 2) Element
 * 3) Modifier
 */
/**
 * 1) VARS
 */

/**
 * 2) Element
 */

[data-dropzone] {
  visibility: hidden;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px dashed darken($color-bg, 15%);
  background: $color-bg;
  opacity: 0;
  z-index: 99999999;

  .inner {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    margin-bottom: 0;

    h3 {
      margin-bottom: 0;
    }
  }
}

.draggingActive {
  [data-dropzone] {
    visibility: visible;
    opacity: .7;

    transition: all .3s ease;
  }
}

.dragging {
  opacity: .3;
}

.draggingOver {
  border: 1px dotted $color-grey;
}

.cms__shopuser {
  clear: both;
  position: relative;

  .pagination__bar {
    padding: 10px 0;
    text-align: right;

    ul {
      li {
        display: inline-block;
        margin: 0 1px;

        &:last-of-type {
          margin-right: 0;
        }

        span {
          text-decoration: none;
          color: $color-text;
          background: $color-bg;
          width: 30px;
          height: 30px;
          line-height: 30px;
          display: inline-block;
          text-align: center;
          font-family: $font-serif;
          margin: 0 2px 10px 2px;
          font-size: 14px;
        }

        &.disabled {
          a {
            display: none;
          }
        }

        &.active {
          a {
            background-color: $color-primary;
            color: $color-white;
          }
        }

        @include respond-min($md) {
          &:hover {
            a {
              background-color: $color-primary;
              color: $color-white !important;
            }
          }
        }
      }
    }
  }

  .guest {
    max-width: 370px;
    width: 100%;

    h4 {
      font-weight: $font-regular !important;
      color: $color-text;
      font-size: 14px !important;
      margin-bottom: 0;

      .fa, .fab, .fal, .far, .fas {
        margin-right: 10px;
      }
    }

    .guest__button {
      background: none !important;
      border: solid 1px $color-text;
      text-decoration: none;
      text-align: center;
      color: $color-text;
      width: 100%;
      display: inline-block;
      line-height: 40px;
      min-height: 40px;
      padding: 0 30px 0 30px;
      font-size: 13px !important;
      text-transform: uppercase;
      font-weight: $font-regular;
      transition: all .3s ease;
      cursor: pointer;
      margin-bottom: 10px;

      @include respond-min($md) {
        &:hover {
          transition: all .3s ease;
          background-color: none;
          color: $color-secondary;
          border-color: $color-secondary;
        }
      }
    }

  }

  .cart_option__user {
    margin-bottom: 20px;

    &.current {

      .cart_toggle__boxes {
        display: block;
      }

      .cart_toggle__title {
        background-color: $color-bg;

        &:before {
          content: "";
          position: relative;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          display: inline-block;
          top: 2px;
          margin-right: 5px;
        }

        &:after {
          content: "\f00c";
          font-family: "Font Awesome 5 Pro";
          position: absolute;
          color: $color-text;
          top: 15px;
          left: 17px;
          display: inline-block;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .cart_toggle__title {
      position: relative;
      padding: 15px;
      cursor: pointer;
      background-color: $color-bg;

      &:before {
        content: "";
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: $color-white;
        border: 1px solid $color-text;
        display: inline-block;
        top: 2px;
        margin-right: 5px;
      }

      h2 {
        font-weight: $font-light;
        width: calc(100% - 30px);
        font-size: 17px;
        display: inline-block;
        margin-bottom: 0 !important;
      }
    }

    .cart_toggle__boxes {
      display: none;
      padding: 30px 0 0 0;
    }
  }

  .login__container {
    //max-width: 370px;
    width: 100%;
    //padding: 30px;
    margin: 0 auto;
    //border: 01px solid $color-bg;
    border-radius: 0px;
    overflow: hidden;
    //display: none;

    &.showlogin {
      display: block !important;
    }

    label, .title {
      font-size: 12px;
      text-transform: uppercase;
      display: inline-block;
      background: white;
      position: relative;
      top: 12px;
      padding: 5px;
      height: 20px;
      line-height: 20px;
      left: 10px;
      font-weight: bold;
      color: $color-text;
    }

    .button {
      height: 45px;
      line-height: 45px;
      background-color: $color-green;
      font-size: 16px;
      padding: 0 30px;
      width: 100%;

      @include respond-max(767px) {
        width: 100%;
      }
    }

    input[type="text"], input[type="password"], input[type="email"], input.text, input.form-control, input[type="number"] {
      padding: 15px 15px 0 10px !important;
    }

    input[type=submit] {
      margin-top: 20px;
      cursor: pointer;
      width: 100%;
    }

    .forgotten {

      a {
        font-weight: $font-bold;
        text-transform: uppercase;
        font-size: 11px;

        @include respond-min($md) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    input[type=text] {
      font-size: 11px;
    }

    *::-webkit-input-placeholder, textarea, textarea.form-control, *::-webkit-input-textarea, -webkit-input-placeholder, input:-moz-placeholder {
      opacity: 0;
      color: transparent !important;
    }

    ::-webkit-input-placeholder {
      opacity: 0;
      color: transparent !important;
    }

    :-moz-placeholder { /* Firefox 18- */
      opacity: 0;
      color: transparent !important;
    }

    ::-moz-placeholder { /* Firefox 19+ */
      opacity: 0;
      color: transparent !important;
    }

    :-ms-input-placeholder {
      opacity: 0;
      color: transparent !important;
    }
  }

  textarea.error::-webkit-input-placeholder, input.form-control.error::-webkit-input-placeholder, input.keywords.error::-webkit-input-placeholder, input.text.error::-webkit-input-placeholder, input[type=email].error::-webkit-input-placeholder, input[type=password].error::-webkit-input-placeholder, input[type=search].error::-webkit-input-placeholder, input[type=text].error::-webkit-input-placeholder {
    color: #f44336 !important;
  }

  .success__box {
    position: relative;
    background: #add47f;
    padding: 10px;
    color: $color-white;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 5px;
  }

  .error__box {
    position: relative;
    padding: 10px;
    background: #faaea8;
    color: $color-white;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 5px;

    &.clickthis {
      margin: 0 0 10px 0 !important;
      cursor: pointer;
    }
  }

  .info__box {
    position: relative;
    background: #ffe5bf;
    padding: 10px;
    color: #ff9800;
    margin: 10px 0;
    font-size: 14px;
    border-radius: 5px;

    &.clickthis {
      margin: 0 0 10px 0 !important;
      cursor: pointer;
      background: #e4f1f8;
      color: #2196F3;
      text-align: center;
      font-weight: $font-bold;

      @include respond-min($md) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .error__text {
    position: relative;
    color: #f44336;
    margin: 0px 0 10px 0;
    overflow: hidden;
    display: block;
    clear: both;
    font-size: 14px;
  }

  a:not([class]) {
    color: $color-text;
    text-decoration: underline;

    @include respond-min($md) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .cart__totals {
    .inner {
      .title {
        //text-transform: uppercase;
        //font-weight: $font-bold;
        font-size: 14px;
      }

      .data {
        font-weight: $font-bold;
        font-size: 16px;
      }

      .subtotal {
        margin-bottom: 20px;
      }

      .discount__price .title, .discount__price .data {
        font-weight: $font-light;

        .remove {
          background: none !important;
          color: $color-black !important;
          border: 0px !important;
          padding: 0 8px 0 0 !important;
          font-size: 16px;
          cursor: pointer;

          @include respond-min($md) {
            &:hover {
              color: #f44336 !important;
            }
          }
        }
      }

      .vat__prices, .vat__percentage {
        .title, .data {
          font-weight: $font-light !important;
        }
      }

      .total {
        .title {
          font-size: 18px;

          .vat__title {
            margin-left: 3px;
            font-weight: $font-light !important;
            font-size: 10px !important;
            text-transform: none !important;

            @include respond-max(400px) {
              display: block;
              margin-left: 0px;
            }
          }
        }

        .data {
          font-size: 20px;
        }
      }
    }
  }

  .user__col {
    .bg {
      > .inner {
        padding: 20px;
        background-color: $color-bg;
      }
    }

    .lines {
      > .inner {
        padding: 20px;
        border: 1px solid $color-bg;
      }
    }


    .account__orders {
      .order {
        font-size: 14px;

        hr {
          border-color: darken($color-bg, 10%);
          margin: 10px 0;
        }

        .line {
          padding: 4px;
          line-height: 15px;

          .data {
            font-weight: $font-bold;
          }
        }
      }
    }

    .account__table {
      .table__row {
        font-size: 14px;
        line-height: 18px;
        border-bottom: 1px solid darken($color-bg, 5%);

        hr {
          border-color: darken($color-bg, 10%);
          margin: 10px 0;
        }

        .line {
          padding: 4px;
          line-height: 15px;

          .data {
            font-weight: $font-bold;
          }
        }

        .toggler {
          color: $color-text;
          cursor: pointer;
        }

        &:last-child {
          border-bottom: 0;
        }

        .title {
          font-weight: $font-bold;
        }

        &.titles {
          .bg {
            .inner {
              padding: 10px 20px;
              font-weight: $font-bold;
              background-color: darken($color-bg, 5%);
            }
          }
        }

        .hidden__rows {
          background-color: lighten($color-bg, 4%);
          display: none;
          font-size: 13px;

          &.active {
            display: block;
          }

          .sub__item {
            padding: 0px 10px 10px 10px;

            &:first-child {
              padding-top: 10px;
            }

            &:last-of-type {
              hr {
                display: none !important;
              }
            }
          }
        }

        @include respond-min($md) {
          .item {
            a {
              &:hover {
                color: $color-green;
              }
            }
          }
        }
        @include respond-min($sm) {
          .flex {
            display: flex;
            flex-direction: row;
            flex-grow: 0;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;

            .item {
              width: 40px;
              flex-grow: 1;
              overflow: hidden;
              padding: 3px 2px;
              word-break: break-word;
              line-height: 14px;

              &:first-child, &:nth-child(2), &:nth-child(4) {
                text-align: left;
                width: 16% !important;
                flex-grow: unset !important;
              }

              &:nth-child(5) {
                text-align: right;
                width: 50px !important;
                flex-grow: unset !important;
              }

              &.title {
                font-weight: $font-bold;
              }
            }
          }
        }
      }
    }

    .account__points {
      .info {
        font-size: 14px;

        hr {
          border-color: darken($color-bg, 10%);
          margin: 10px 0;
        }

        .points {
          font-size: 18px;
          padding: 4px;
          font-weight: $font-bold;

          .green {
            color: $color-green;
          }
        }

        .line {
          padding: 4px;
          line-height: 15px;
        }

      }
    }

    .account__addresses {
      .inner {
        border: 1px solid $color-bg;
      }

      .selected {
        background-color: $color-white !important;
        border: 1px solid $color-bg;
        padding-top: 40px;

        .select {
          text-align: center;
          background-color: $color-text;
          display: block;
          width: calc(100% - 20px);
          color: $color-white;
          position: absolute;
          font-size: 15px;
          top: 0px;
          left: 10px;
        }
      }

      hr {
        border-color: darken($color-bg, 10%);
        margin: 10px 0;
      }

      .address__item_addressbook {
        font-size: 14px;

        .address__col {
          .inner {
            margin-bottom: 0px;
          }
        }

        .boxed {
          overflow: hidden;
          display: flex;
          align-items: center;

          .icon {
            width: 25px;
            float: left;

            &.edit {
              width: 15px;
              float: right;
              position: relative;
            }

            i {
              color: $color-grey !important;
              background: none !important;
              padding: 0 !important;
              font-size: 22px !important;
              font-weight: 300 !important;
              width: 100%;
              position: relative;
              border: 0 !important;

              &:hover {
                color: $color-text !important;

                &[title] {
                  &:after {
                    text-transform: none;
                    content: attr(title);
                    padding: 10px;
                    color: #444;
                    right: 18px;
                    position: absolute;
                    text-align: left;
                    overflow: hidden;
                    top: 0;
                    right: 20px;
                    display: block;
                    font-family: $font-sans;
                    font-size: 14px;
                    z-index: 2000000;
                    -moz-border-radius: 5px;
                    -webkit-border-radius: 5px;
                    box-shadow: 0 0 3px rgba(0, 0, 0, .5);
                    background: #fff;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }

        .flex {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -webkit-justify-content: left;
          -ms-flex-pack: left;
          justify-content: left;
          flex-flow: wrap;
          align-content: center;
        }

        .edit {
          align-self: end;
        }

        .address {
          width: calc(100% - 70px);
          float: left;
          cursor: pointer;
          font-size: 15px;
        }
      }
    }

    .account__personaldata {
      .inner {
        font-size: 14px;
      }

      hr {
        border-color: darken($color-bg, 10%);
        margin: 10px 0;
      }

      .details {
        font-size: 14px;

        .line {
          padding: 4px;
          line-height: 15px;

          .data {
            font-weight: $font-bold;
          }
        }

      }
    }

    .account__personaldata {

      .normal__inputs {

        &.checkbox__field {
          .checkbox {
            position: relative;
            display: inline-block;
            margin-right: 5px;
            min-height: 40px;
            line-height: 20px;
            width: 100%;
            border: 1px solid $color-bg;
            cursor: pointer;
            font-size: 15px;
            padding-left: 25px;

            input {
              visibility: hidden;
              width: 0;
              margin-right: 6px;
            }

            &.active {
              background-color: $color-bg;

              &:after {
                content: "\f00c";
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                color: $color-text;
                top: 4px;
                left: 4px;
                display: inline-block;
              }
            }

            &:before {
              content: "";
              position: relative;
              width: 20px;
              height: 20px;
              border: 1px solid $color-bg;
              background-color: $color-white;
              display: inline-block;
              top: 4px;
              margin-left: -25px;
            }
          }
        }

        .radio {
          position: relative;
          display: inline-block;
          margin-left: 15px;
          min-height: 40px;
          line-height: 20px;
          cursor: pointer;
          font-size: 15px;
          padding-left: 25px;

          input {
            visibility: hidden;
            width: 0;
            margin-right: 0;
          }

          &.active {

            &:after {
              content: "\f00c";
              font-family: "Font Awesome 5 Pro";
              position: absolute;
              color: $color-text;
              top: 0px;
              left: 3px;
              display: inline-block;
            }

            &:before {
              border: 1px solid $color-text;
            }
          }

          &:before {
            content: "";
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 1px solid darken($color-bg, 15%);
            background-color: $color-white;
            display: inline-block;
            top: 4px;
            margin-left: -25px;
          }
        }
      }

      .customer__inputs {

        .radio {
          position: relative;
          display: inline-block;
          margin-right: 5px;
          padding: 0 15px;
          min-height: 40px;
          line-height: 20px;
          border: 1px solid $color-bg;
          background-color: $color-white;
          cursor: pointer;
          font-size: 15px;

          input {
            visibility: hidden;
            width: 0;
            margin-right: 0;
          }

          &.active {
            background-color: $color-bg;

            &:after {
              content: "\f00c";
              font-family: "Font Awesome 5 Pro";
              position: absolute;
              color: $color-text;
              top: 0px;
              left: 17px;
              display: inline-block;
            }

            &:before {
              border: 1px solid $color-text;
            }
          }

          &:before {
            content: "";
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 1px solid $color-bg;
            background-color: $color-white;
            display: inline-block;
            top: 4px;
          }
        }

        .company__fields {
          background-color: $color-bg;
          padding: 20px 25px;
          display: none;

          .inputfield {
            padding-left: 5px;
            padding-right: 5px;
          }

          &.active {
            display: block;
          }
        }
      }
    }

    .account__favorites {

      .addgroup {

        .edit__this {
          position: relative;

          &:before {
            content: "\f303";
            position: absolute;
            left: 8px;
            top: 3px;
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            color: $color-grey;
            font-size: 22px;
          }

          input {
            background-color: transparent;
            border: dashed 1px transparent;
            font-size: 24px !important;
            font-weight: $font-light;
            color: $color-grey;
            padding-left: 35px;

            &:focus {
              color: $color-text;
            }

            @include respond-min($md) {
              &:hover, &:focus {
                border: dashed 1px $color-grey;
              }
            }
          }
        }
      }

      .intro {
        .link {
          font-size: 14px;
        }

        hr {
          border-color: darken($color-bg, 10%);
          margin: 10px 0;
        }

        .line {
          padding: 4px;
          line-height: 15px;

          .data {
            font-weight: $font-bold;
          }
        }
      }

      .products {
        min-height: 60px;

        .product-item_favorite {
          text-decoration: none;
          margin-bottom: 10px;

          &:last-child {
            > .inner {
              border-bottom: none;
            }
          }

          @include respond-min($md) {
            &:last-child {
              > .inner {
                border-bottom: none;
              }
            }
          }

          .inner {
            padding: 4px 0 10px 0 !important;
            overflow: hidden;
            border-bottom: 1px solid $color-bg;
          }

          .order {
            @include respond-min($lg) {
              display: flex;
              justify-content: space-between;
              flex-direction: column-reverse;
              align-items: flex-start;
              height: 100%;
            }

            @include respond-max(1199px) {
              line-height: 45px;
              display: flex;
              justify-content: space-between;
              flex-direction: column-reverse;
              align-items: flex-start;
            }

            .addtocart {
              padding: 0px !important;
              height: 40px !important;
              width: 45px !important;
              line-height: 40px !important;
              display: inline-block;
              text-align: center;
              background-color: $color-green;
              font-weight: $font-light;
              font-family: $font-serif;
              font-size: 18px;
              float: right;
              margin-top: 0 !important;
              margin-left: 5px;

              .ui-icon {
                display: none;
              }

              &:before {
                content: "\f067";
                transition: all .3s ease;
                margin-right: 0px;
                font-weight: 300;
                font-size: 18px;
                font-family: "Font Awesome 5 Pro";
              }

              @include respond-min($md) {
                &:hover {
                  background-color: darken($color-green, 10%) !important;

                  &:after {
                    transition: all .3s ease;
                    margin-left: 15px;
                  }
                }
              }
            }

            .amountbox {
              @include respond-min($lg) {
                align-self: flex-end;
              }

              .qtyminus, .qtyplus {
                max-width: 40px;
                top: 1px;
                position: relative;
                min-width: 20px;
                line-height: 44px;
                min-height: 44px;
                padding: 0 5px;
                float: left;
                color: darken($color-bg, 25%);
                background-color: darken($color-bg, 10%);
              }

              .qtyplus {
                border-radius: 0 4px 4px 0;
              }

              .qtyminus {
                border-radius: 4px 0 0 4px;
              }

              input {
                max-width: 40px;
                margin-bottom: 0;
                margin-top: 1px;
                padding: 0 5px;
                height: 44px;
                line-height: 44px;
                border-radius: 0;
                float: left;
                font-size: 14px !important;
                background-color: $color-white;
                text-align: center;
                color: darken($color-bg, 25%);
              }
            }
          }

          .edit__text {
            position: relative;

            &:before {
              content: "\f303";
              position: absolute;
              left: 8px;
              top: 8px;
              font-family: "Font Awesome 5 Pro";
              font-weight: 300;
              color: $color-grey;
              font-size: 14px;
            }

            input {
              background-color: transparent;
              border: dashed 1px transparent;
              font-size: 14px !important;
              font-weight: $font-bold;
              color: $color-text;
              padding-left: 35px;

              &:focus {
                color: $color-text;
              }

              @include respond-min($md) {
                &:hover, &:focus {
                  border: dashed 1px $color-grey;
                }
              }
            }
          }

          .edit_fav {
            @include respond-min($lg) {
              align-self: flex-end;
              display: flex;
              justify-content: flex-end;
            }

            a {
              @include respond-min($md) {
                &:hover {
                  .fa, .fab, .fal, .far, .fas {
                    color: $color-text;
                  }
                }
              }
            }

            .fa, .fab, .fal, .far, .fas {
              font-family: "Font Awesome 5 Pro";
              font-weight: 300;
              color: $color-grey;
              font-size: 18px;
            }
          }

          .info {

            @include respond-max(1199px) {
              &.flex {
                display: flex;
                justify-content: space-between;
              }
            }

            h2 {
              font-size: 16px;
              color: $color-black;
              font-weight: $font-regular;
              margin-bottom: 3px;

              @include respond-max(480px) {
                font-size: 14px;
              }
            }

            .prices {
              .price {
                font-weight: $font-black;
                color: $color-green;
                font-size: 18px;
                float: left;

                @include respond-max(480px) {
                  font-size: 16px;
                }

                span.vat {
                  text-transform: lowercase !important;
                }
              }

              .oldprice {
                position: relative;
                margin-right: 10px;
                font-weight: $font-regular;
                font-size: 18px;
                color: $color-grey;
                text-decoration: line-through;
                float: left;

                @include respond-max(480px) {
                  font-size: 16px;
                }
              }
            }

          }

          @include respond-min($md) {
            &:hover {
              .image {
                -webkit-transform: scale(1.05);
                -ms-transform: scale(1.05);
                transform: scale(1.05);
                position: relative;
                transition: all .3s ease;
              }
            }
          }

          .zoom {
            overflow: hidden;
            border-bottom: 1px solid $color-bg;
            position: relative;

            .favorite {
              .fa {
                font-weight: $font-regular;
                color: $color-green;
                position: absolute;
                top: 5px;
                left: 5px;
                z-index: 4;
                font-size: 22px;

                &.active {
                  color: $color-green;
                  font-weight: 600;
                }

                @include respond-min($md) {
                  &:hover {
                    color: $color-primary;
                  }
                }
              }
            }

          }

          .image {
            transition: all .3s ease;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            padding-bottom: 75%;
            position: relative;
          }
        }

        .product__item-favorites {
          text-decoration: none;
          margin-bottom: 10px;

          &:last-child {
            > .inner {
              border-bottom: none;
            }
          }

          @include respond-min($md) {
            &:nth-last-child(-n+2) {
              > .inner {
                border-bottom: none;
              }
            }
          }

          .inner {
            padding: 4px 0 !important;
            overflow: hidden;
            border-bottom: 1px solid $color-bg;
          }

          .more {
            width: 20px;
            height: 20px;
            line-height: 20px;
            border-radius: 100%;
            text-align: center;
            background-color: $color-green;
            color: $color-white;
            display: block;
            position: absolute;
            right: 20px;
            bottom: 10px;

            @include respond-min($md) {
              &:hover {
                background-color: $color-text;
              }
            }
          }

          .info {
            float: right;
            width: calc(100% - 60px);

            @include respond-max(480px) {
              width: calc(66% - 30px);
              padding-left: 10px;
              float: left;
            }

            h2 {
              font-size: 14px;
              color: $color-black;
              font-weight: $font-regular;
              margin-bottom: 3px;
              width: calc(100% - 20px);

              @include respond-max(480px) {
                font-size: 14px;
              }
            }

            .prices {
              .price {
                font-weight: $font-black;
                color: $color-green;
                font-size: 14px;
                float: left;

                @include respond-max(480px) {
                  font-size: 14px;
                }

                span.vat {
                  text-transform: lowercase !important;
                }
              }

              .oldprice {
                position: relative;
                margin-right: 10px;
                font-weight: $font-regular;
                font-size: 14px;
                color: $color-grey;
                text-decoration: line-through;
                float: left;

                @include respond-max(480px) {
                  font-size: 14px;
                }
              }
            }

          }

          @include respond-min($md) {
            &:hover {
              .image {
                -webkit-transform: scale(1.05);
                -ms-transform: scale(1.05);
                transform: scale(1.05);
                position: relative;
                transition: all .3s ease;
              }
            }
          }

          .label {
            position: absolute;
            top: 20px;
            left: 10px;
            font-size: 15px;
            padding: 6px 10px;
            background-color: $color-primary;
            border-radius: 0 4px 4px 0;
            color: $color-white;
            z-index: 15;

            @include respond-max(480px) {
              left: auto;
              border-radius: 4px 0 0 4px;
              right: 10px;
              font-size: 12px;
              padding: 4px 8px;
            }

            &.sale {
              background-color: $color-secondary;
              left: auto;
              right: 10px;
              border-radius: 4px 0 0 4px;
            }
          }

          .zoom {
            overflow: hidden;
            padding: 0px;
            border-bottom: 1px solid $color-bg;
            position: relative;
            float: left;
            width: 50px;

            .favorite {
              .fa {
                font-weight: $font-regular;
                color: $color-green;
                position: absolute;
                top: 5px;
                left: 5px;
                z-index: 4;
                font-size: 22px;

                &.active {
                  color: $color-green;
                  font-weight: 600;
                }

                @include respond-min($md) {
                  &:hover {
                    color: $color-primary;
                  }
                }
              }
            }

            @include respond-max(480px) {
              width: 33%;
              float: left;
            }

          }

          .image {
            transition: all .3s ease;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            padding-bottom: 75%;
            position: relative;
          }
        }
      }

    }

    .account__favorites-group {

      .intro {
        border-top: 1px solid $color-bg;
        padding-top: 5px;
        border-bottom: 1px solid $color-bg;
      }

      .edit {

        a {
          @include respond-min($md) {
            &:hover {
              .fa, .fab, .fal, .far, .fas {
                color: $color-text;
              }
            }
          }
        }

        .fa, .fab, .fal, .far, .fas {
          font-family: "Font Awesome 5 Pro";
          font-weight: 300;
          color: $color-grey;
          font-size: 22px;
          line-height: 45px;
        }
      }

      .edit__this {
        position: relative;

        &:before {
          content: "\f303";
          position: absolute;
          left: 8px;
          top: 3px;
          font-family: "Font Awesome 5 Pro";
          font-weight: 300;
          color: $color-grey;
          font-size: 22px;
        }

        input {
          background-color: transparent;
          border: dashed 1px transparent;
          font-size: 24px !important;
          font-weight: $font-bold;
          color: $color-text;
          padding-left: 35px;

          &:focus {
            color: $color-text;
          }

          @include respond-min($md) {
            &:hover, &:focus {
              border: dashed 1px $color-grey;
            }
          }
        }
      }
    }
  }

  .content__col {
    h1 {
      font-size: 28px !important;
      color: $color-text !important;

      @include respond-max(400px) {
        font-size: 22px !important;
      }
    }

    h2 {
      font-size: 20px !important;
      color: $color-text !important;

      @include respond-max(400px) {
        font-size: 14px !important;
      }
    }

    h3 {
      font-size: 16px !important;
      color: $color-text;
      font-weight: $font-bold !important;
      margin-bottom: 0;

      @include respond-max(400px) {
        font-size: 14px !important;
      }

      a {
        text-decoration: none !important;

        @include respond-min($md) {
          &:hover {
            color: lighten($color-text, 20%);
          }
        }
      }
    }

    h4 {
      font-weight: $font-regular !important;
      color: $color-text;
      font-size: 16px !important;
      margin-bottom: 0;

      @include respond-max(400px) {
        font-size: 14px !important;
      }

      .fa, .fab, .fal, .far, .fas {
        margin-right: 10px;
      }
    }

    p {
      color: $color-text;
      font-size: 15px !important;
      margin-bottom: 0;
    }
  }
}


.account__menu {
  padding: 30px;
  border: 1px solid $color-bg;
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: block;
  clear: both;
  overflow: hidden;

  .nav__account {
    margin-left: 0 !important;
    position: relative;
    margin-bottom: 0;

    .nav__item, li {
      display: block;

      .nav__sub, ul {
        padding: 10px;
        margin-left: 0 !important;
        display: inline-block !important;
        position: relative;
        background: none !important;
        margin-bottom: 0;
        max-width: 100%;
        width: calc(100% - 0px);
        overflow: visible;

        .nav__item, li {

          &.selected {
            > .nav__link, > a {
              font-weight: $font-black !important;
              color: $color-primary;
            }
          }

          &:hover {
            > .nav__link, > a {
              color: $color-primary;
            }
          }

          .nav__link, a {
            padding: 6px 0;
            line-height: 15px;
            min-height: 15px;
            display: block;
            width: 100%;
            font-size: 15px;
            color: $color-black;
            text-decoration: none;
          }
        }
      }

      &.selected {
        > .nav__link, > a {
          font-weight: $font-black !important;
          color: $color-primary;
        }
      }

      &:hover {
        > .nav__link, > a {
          color: $color-primary;
        }
      }

      &:first-child {
        border-top: 0px;
      }

      .nav__link, a {
        padding: 8px 0;
        line-height: 20px;
        min-height: 20px;
        display: block;
        width: 100%;
        font-size: 16px;
        color: $color-black;
        text-decoration: none;
      }
    }

  }

  @include respond-max(991px) {
    padding: 10px 20px;

    h2 {
      margin-bottom: 0px;
      transition: all .3s ease;

      &:after {
        content: "\f078";
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        font-size: 22px;
        color: $color-text;
        text-align: right;
        float: right;
      }

      &.js-mobile-menu-active {
        margin-bottom: 10px;
        transition: all .3s ease;
      }
    }

    .nav__account {
      visibility: none;
      opacity: 0;
      height: 0;
      transition: all .3s ease;

      &.js-mobile-menu-active {
        visibility: visible;
        opacity: 1;
        height: auto;
        transition: all .3s ease;
      }
    }
  }

}

/**
 * 3) Modifier
 */
